import React, { useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import './SearchBar.css';

function SearchBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="search-bar">
      <input 
        type="text" 
        placeholder="Pesquisar por nome ou código..." 
        value={searchTerm}
        onChange={handleSearch}
      />
      <button>
        <SearchOutlined />
      </button>
    </div>
  );
}

export default SearchBar;
