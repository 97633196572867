import { useState, useEffect } from 'react';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateSession = async () => {
      const token = localStorage.getItem('token');
      
      if (!token) {
        setAuthenticated(false);
        setLoading(false);
        return;
      }

      try {
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const response = await api.get('/api/auth/verify');
        
        if (response.data.valid) {
          setAuthenticated(true);
          // Decodificar o token e armazenar os dados do usuário
          const userData = JSON.parse(atob(token.split('.')[1]));
          setUser(userData);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Erro ao validar sessão:', error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    validateSession();
  }, []);

  async function handleLogin(email, senha) {
    try {
      const { data } = await api.post('/api/auth/login', { email, senha });
      localStorage.setItem('token', data.token);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      setAuthenticated(true);
      setUser(data.usuario);
      navigate('/dashboard');
    } catch (error) {
      message.error('Email ou senha inválidos!');
    }
  }

  function handleLogout() {
    setAuthenticated(false);
    setUser(null);
    localStorage.removeItem('token');
    api.defaults.headers.Authorization = undefined;
    navigate('/login');
  }

  return { authenticated, loading, user, handleLogin, handleLogout };
}
