import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Statistic, Spin, Button, message } from 'antd';
import {
  UserOutlined,
  ShopOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DollarCircleOutlined,
  PieChartOutlined,
  BarChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'; // Importe Tooltip e Legend
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate

const { Title } = Typography;

const AdminDashboard = () => {
  const [estatisticas, setEstatisticas] = useState({
    totalConcessionarias: 0,
    totalVendedores: 0,
    totalAnuncios: 0,
    totalAnunciosAtivos: 0,
    totalAnunciosVendidos: 0,
  });
  const [loading, setLoading] = useState(false);
  const [anunciosPorStatus, setAnunciosPorStatus] = useState([]);
  const navigate = useNavigate(); // Inicialize useNavigate

  useEffect(() => {
    buscarEstatisticas();
  }, []);

  const buscarEstatisticas = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/relatorios/admin', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setEstatisticas(response.data.estatisticas);
      setAnunciosPorStatus(response.data.anunciosPorStatus);
    } catch (error) {
      message.error('Erro ao buscar estatísticas.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const data = anunciosPorStatus.map((item) => ({
    name: item.status,
    value: item.quantidade,
  }));

  const COLORS = ['#0088FE', '#FF8042', '#00C49F'];

  return (
    <div>
      <Title level={2}>Informações Gerais</Title>
      <Spin spinning={loading}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Concessionárias" value={estatisticas.totalConcessionarias} prefix={<ShopOutlined />} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Vendedores" value={estatisticas.totalVendedores} prefix={<UserOutlined />} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Anúncios" value={estatisticas.totalAnuncios} prefix={<CarOutlined />} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Anúncios Ativos" value={estatisticas.totalAnunciosAtivos} prefix={<CheckCircleOutlined />} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Anúncios Vendidos" value={estatisticas.totalAnunciosVendidos} prefix={<DollarCircleOutlined />} />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card>
              <Statistic title="Anúncios Inativos" value={estatisticas.totalAnuncios - estatisticas.totalAnunciosAtivos} prefix={<CloseCircleOutlined />} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col xs={24} md={12}>
            <Card title="Anúncios por Status">
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card title="Ações">
              <Button type="primary" icon={<UserOutlined />} onClick={() => navigate('/dashboard/admin/gerenciar-administradores')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Gerenciar Administradores
              </Button>
              <Button type="primary" icon={<ShopOutlined />} onClick={() => navigate('/dashboard/admin/gerenciar-concessionarias')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Gerenciar Concessionárias
              </Button>
              <Button type="primary" icon={<UserOutlined />} onClick={() => navigate('/dashboard/admin/gerenciar-vendedores')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Gerenciar Vendedores
              </Button>
              <Button type="primary" icon={<CarOutlined />} onClick={() => navigate('/dashboard/admin/gerenciar-anuncios')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Gerenciar Anúncios
              </Button>
              <Button type="primary" icon={<PieChartOutlined />} onClick={() => navigate('/dashboard/admin/relatorios')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Relatórios
              </Button>
              <Button type="primary" icon={<SettingOutlined />} onClick={() => navigate('/dashboard/admin/configuracoes')} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
                Configurações
              </Button>
            </Card>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default AdminDashboard;