import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, Row, Col, Card } from 'antd';
import axios from 'axios';

const { Title } = Typography;

const SolicitarConcessionaria = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.post(process.env.REACT_APP_API_URL + '/api/solicitacoes-concessionaria', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Solicitação enviada com sucesso! Aguarde a aprovação do administrador.');
      form.resetFields();
    } catch (error) {
      message.error('Erro ao enviar solicitação.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Solicitar Criação de Conta de Concessionária</Title>
          <Form form={form} name="solicitacaoConcessionariaForm" onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Nome da Concessionária"
              name="nome"
              rules={[{ required: true, message: 'Por favor, insira o nome da concessionária!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              rules={[{ required: true, message: 'Por favor, insira o CNPJ!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Razão Social"
              name="razaoSocial"
              rules={[{ required: true, message: 'Por favor, insira a razão social!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Por favor, insira o email!' },
                { type: 'email', message: 'Por favor, insira um email válido!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Telefone"
              name="telefone"
              rules={[{ required: true, message: 'Por favor, insira o telefone!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Logradouro"
              name="logradouro"
              rules={[{ required: true, message: 'Por favor, insira o logradouro!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Número"
              name="numero"
              rules={[{ required: true, message: 'Por favor, insira o número!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Complemento" name="complemento">
              <Input />
            </Form.Item>
            <Form.Item
              label="Bairro"
              name="bairro"
              rules={[{ required: true, message: 'Por favor, insira o bairro!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Cidade"
              name="cidade"
              rules={[{ required: true, message: 'Por favor, insira a cidade!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Estado"
              name="estado"
              rules={[{ required: true, message: 'Por favor, insira o estado!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Sua Relação com a Concessionária"
              name="relacaoConcessionaria"
              rules={[{ required: true, message: 'Por favor, descreva sua relação com a concessionária!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Enviar Solicitação
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SolicitarConcessionaria;