import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  message,
  Typography,
  InputNumber,
  Select,
  Row,
  Col,
  Card,
  Avatar,
  Popconfirm,
  Modal,
  Space,
  Upload,
  Spin,
  Tag,
  Divider,
  Input as AntInput,
  Statistic,
} from 'antd';
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  CarOutlined,
  DollarOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { jwtDecode } from 'jwt-decode';
import Chance from 'chance';
import api from '../../services/api';
import axios from 'axios';

const { Title, Paragraph } = Typography;
const { Option } = Select;

// Adicione este estilo personalizad
const buttonStyle = {
  backgroundColor: '#FF6600',
  borderColor: '#FF6600',
};

const PublicarAnuncio = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [concessionariaId, setConcessionariaId] = useState(null);
  const [vendedorId, setVendedorId] = useState(null);
  const [fotosParaUpload, setFotosParaUpload] = useState([]); // Fotos para upload
  const [fotosUrls, setFotosUrls] = useState([]); // URLs das fotos
  const [uploadProgress, setUploadProgress] = useState({}); // Progresso do upload
  const [anuncios, setAnuncios] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal de edição
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false); // Modal de criação
  const [editFormData, setEditFormData] = useState({
    id: null,
    titulo: '',
    descricao: '',
    marca: '',
    modelo: '',
    ano: null,
    preco: null,
    quilometragem: null,
    combustivel: '',
    cor: '',
    status: 'ativo',
    tipoAnuncio: null,
  });

  // Estado para controlar o tipo de anúncio selecionado
  const [tipoAnuncio, setTipoAnuncio] = useState(null);

  // Estados para pesquisa e filtro
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Adicione este estado
  const [fotosAtuais, setFotosAtuais] = useState([]);

  const [editTipoAnuncio, setEditTipoAnuncio] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setVendedorId(decodedToken.userId);
        setConcessionariaId(decodedToken.concessionariaId); // Extrai a concessionariaId do token
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (vendedorId) {
      buscarAnuncios();
    }
  }, [vendedorId]);

  const buscarAnuncios = async () => {
    try {
      const response = await api.get('/api/anuncios', {
        params: { vendedorId: vendedorId }
      });
      setAnuncios(response.data);
    } catch (error) {
      message.error('Erro ao buscar anúncios.');
      console.error(error);
    }
  };

  // Função para lidar com o upload de fotos
  const handleUpload = async (values) => {
    setLoading(true);
    try {
      // Fazer upload das fotos e obter as URLs
      const fotosUrls = await Promise.all(
        fotosParaUpload.map(async (foto) => {
          const formData = new FormData();
          formData.append('foto', foto);

          const response = await api.post('/api/upload/foto', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress((prevProgress) => ({
                ...prevProgress,
                [foto.uid]: percentCompleted,
              }));
            },
          });

          return response.data.url;
        })
      );

      // Criar o objeto anuncioData com as URLs das fotos e o tipo de anúncio
      const anuncioData = {
        ...values,
        ano: parseInt(values.ano, 10) || 0,
        preco: parseFloat(values.preco) || 0,
        quilometragem: parseInt(values.quilometragem, 10) || 0,
        vendedorid: vendedorId,
        fotos: fotosUrls,
        tipoanuncio: tipoAnuncio, // Usar o valor do estado tipoAnuncio
      };

      // Adicionar concessionariaid apenas se existir
      if (concessionariaId) {
        anuncioData.concessionariaid = concessionariaId;
      }

      // Salvar o anúncio no banco de dados
      const response = await api.post('/api/anuncios', anuncioData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      message.success(`Anúncio de ${tipoAnuncio === 'Veiculo' ? 'veículo' : 'peça'} publicado com sucesso!`);

      // Atualizar a lista de anúncios após a criação
      setAnuncios([...anuncios, response.data]);

      form.resetFields(); // Limpar o formulário após o sucesso
      setFotosParaUpload([]); // Limpar as fotos após o upload
      setFotosUrls([]); // Limpar as URLs das fotos
      setUploadProgress({}); // Limpar o progresso de upload
      setTipoAnuncio(null); // Resetar o tipo de anúncio
      handleCancelPublicar(); // Fechar o modal após publicar
    } catch (error) {
      message.error('Erro ao publicar anúncio.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const gerarDadosAleatorios = () => {
    const chance = new Chance();

    const dadosAleatorios = {
      titulo: chance.sentence({ words: 5 }),
      descricao: chance.paragraph({ sentences: 2 }),
      marca: chance.pickone(['Chevrolet', 'Ford', 'Fiat', 'Volkswagen', 'Toyota', 'Honda', 'Hyundai', 'Renault']),
      modelo: chance.word(),
      ano: chance.year({ min: 2010, max: 2023 }),
      preco: chance.floating({ min: 10000, max: 100000, fixed: 2 }),
      quilometragem: chance.integer({ min: 0, max: 200000 }),
      combustivel: chance.pickone(['Gasolina', 'Álcool', 'Flex', 'Diesel']),
      cor: chance.color({ format: 'name' }),
      status: chance.pickone(['ativo', 'inativo']),
    };

    form.setFieldsValue(dadosAleatorios);
  };

  const handleEditarAnuncio = (anuncio) => {
    console.log('Dados do anúncio para edição:', anuncio);

    // Formatar os dados para o formulário
    const formData = {
      id: anuncio.id, // Garantir que o ID está sendo armazenado
      tipoAnuncio: anuncio.tipoanuncio,
      titulo: anuncio.titulo,
      descricao: anuncio.descricao,
      marca: anuncio.marca,
      modelo: anuncio.modelo,
      ano: anuncio.ano,
      preco: parseFloat(anuncio.preco),
      quilometragem: anuncio.quilometragem,
      combustivel: anuncio.combustivel,
      cor: anuncio.cor,
      status: anuncio.status,
    };

    // Atualizar os estados
    setEditFormData(formData);
    setTipoAnuncio(anuncio.tipoanuncio);
    setFotosUrls(anuncio.fotos || []);
    setIsModalVisible(true);

    // Atualizar o formulário com os dados
    setTimeout(() => {
      form.setFieldsValue(formData);
    }, 100);
  };

  const handleExcluirAnuncio = async (id) => {
    try {
      await api.delete(`/api/anuncios/${id}`);
      message.success('Anúncio excluído com sucesso!');
      buscarAnuncios();
    } catch (error) {
      message.error('Erro ao excluir anúncio.');
      console.error(error);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      
      // Log dos valores recebidos do formulário
      console.log('Valores do formulário:', values);

      // Verificar se temos o ID do anúncio
      if (!editFormData.id) {
        console.error('ID do anúncio não encontrado');
        message.error('Erro ao identificar o anúncio para atualização');
        return;
      }

      // Formatar os dados corretamente antes de enviar
      const updatedAnuncio = {
        ...values,
        id: editFormData.id, // Garantir que o ID está presente
        fotos: fotosUrls,
        tipoanuncio: values.tipoAnuncio,
        // Tratamento explícito de cada campo numérico
        ano: values.ano ? parseInt(values.ano) : 0,
        preco: values.preco ? parseFloat(values.preco) : 0,
        quilometragem: values.quilometragem ? parseInt(values.quilometragem) : 0,
        vendedorId: vendedorId,
        // Garantir que campos opcionais não sejam undefined
        marca: values.marca || null,
        modelo: values.modelo || null,
        combustivel: values.combustivel || null,
        cor: values.cor || null,
        status: values.status || 'ativo'
      };

      // Log para debug
      console.log('ID do anúncio para atualização:', editFormData.id);
      console.log('Dados formatados para atualização:', updatedAnuncio);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/anuncios/${editFormData.id}`, 
        updatedAnuncio,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      console.log('Resposta da atualização:', response.data);

      message.success('Anúncio atualizado com sucesso!');
      setIsModalVisible(false);
      buscarAnuncios();
    } catch (error) {
      console.error('Erro ao atualizar anúncio:', error);
      console.error('Dados que causaram o erro:', error.response?.data);
      
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Erro ao atualizar anúncio. Verifique os dados e tente novamente.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleNumberInputChange = (name, value) => {
    setEditFormData({ ...editFormData, [name]: value });
  };

  // Funções para abrir e fechar o modal de publicar anúncio
  const showModalPublicar = () => {
    setIsCreateModalVisible(true);
    form.resetFields();
  };

  const handleCancelPublicar = () => {
    setIsCreateModalVisible(false);
    form.resetFields();
  };

  // Funções para lidar com a pesquisa e o filtro
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  // Função para lidar com a pesquisa quando o botão é clicado ou Enter é pressionado
  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Função para filtrar os anúncios baseado na pesquisa e categoria
  const filteredAnuncios = anuncios.filter((anuncio) => {
    const matchesSearch = searchTerm
      ? anuncio.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        anuncio.descricao.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    const matchesCategory = selectedCategory
      ? anuncio.tipoanuncio === selectedCategory
      : true;

    return matchesSearch && matchesCategory;
  });

  // Adicione esta função
  const handleRemovePhoto = (url) => {
    setFotosAtuais(prevFotos => prevFotos.filter(foto => foto !== url));
  };

  // Adicione estas funções para calcular as estatísticas
  const getEstatisticasAnuncios = () => {
    const totalAnuncios = anuncios.length;
    const anunciosVeiculos = anuncios.filter(a => a.tipoanuncio === 'Veiculo').length;
    const anunciosPecas = anuncios.filter(a => a.tipoanuncio === 'Peca').length;
    const anunciosAtivos = anuncios.filter(a => a.status === 'ativo').length;

    return {
      total: totalAnuncios,
      veiculos: anunciosVeiculos,
      pecas: anunciosPecas,
      ativos: anunciosAtivos
    };
  };

  useEffect(() => {
    if (editFormData && Object.keys(editFormData).length > 0) {
      console.log('Atualizando formulário com dados:', editFormData);
      form.setFieldsValue(editFormData);
    }
  }, [editFormData, form]);

  return (
    <Row gutter={[16, 16]}>
      {/* Coluna Publicar Anúncio */}
      <Col xs={24} lg={10}>
        <Card>
          <Title level={2}>Publicar Anúncio</Title>
          <Button 
            type="primary" 
            onClick={showModalPublicar} 
            style={{ 
              marginBottom: '1rem', 
              width: '100%',
              ...buttonStyle 
            }}
          >
            Publicar Novo Anúncio
          </Button>

          {/* Estatísticas dos Anúncios */}
          <Divider orientation="left">Estatísticas dos Anúncios</Divider>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Total de Anúncios"
                  value={getEstatisticasAnuncios().total}
                  prefix={<AppstoreOutlined />}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Anúncios Ativos"
                  value={getEstatisticasAnuncios().ativos}
                  prefix={<CheckCircleOutlined />}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Veículos"
                  value={getEstatisticasAnuncios().veiculos}
                  prefix={<CarOutlined />}
                  valueStyle={{ color: '#1890ff' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Peças"
                  value={getEstatisticasAnuncios().pecas}
                  prefix={<ToolOutlined />}
                  valueStyle={{ color: '#722ed1' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* Coluna Meus Anúncios */}
      <Col xs={24} lg={14}>
        <Card>
          <Title level={2}>Meus Anúncios</Title>
          
          {/* Filtros e Pesquisa */}
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={24} md={12}>
              <Select
                placeholder="Filtrar por categoria"
                onChange={handleCategoryChange}
                value={selectedCategory}
                size="large"
                style={{ width: '100%' }}
                allowClear
              >
                <Option value="Veiculo">Veículo</Option>
                <Option value="Peca">Peça</Option>
              </Select>
            </Col>
            <Col xs={24} md={12}>
              <AntInput.Search
                placeholder="Pesquisar anúncios..."
                value={searchTerm}
                onChange={handleSearchChange}
                enterButton="Buscar"
                size="large"
                prefix={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

          {/* Adicione um estilo global para o botão de busca */}
          <style jsx global>{`
            .ant-input-search .ant-input-search-button {
              background-color: #FF6600 !important;
              border-color: #FF6600 !important;
            }
            .ant-input-search .ant-input-search-button:hover {
              background-color: #e65c00 !important;
              border-color: #e65c00 !important;
            }
            .ant-btn-primary {
              background-color: #FF6600 !important;
              border-color: #FF6600 !important;
            }
            .ant-btn-primary:hover {
              background-color: #e65c00 !important;
              border-color: #e65c00 !important;
            }
          `}</style>

          {/* Lista de Anúncios */}
          <Row gutter={[16, 16]}>
            {filteredAnuncios.map((anuncio) => (
              <Col xs={24} sm={24} md={24} lg={12} key={anuncio.id} style={{ display: 'flex' }}>
                <Card
                  style={{ 
                    width: '100%', 
                    marginBottom: '1rem',
                    height: '100%'
                  }}
                  cover={
                    anuncio.fotos && anuncio.fotos.length > 0 && (
                      <img 
                        alt={anuncio.titulo} 
                        src={anuncio.fotos[0]} 
                        style={{ 
                          height: '200px', 
                          objectFit: 'cover' 
                        }} 
                      />
                    )
                  }
                  actions={[
                    <EditOutlined 
                      key="edit" 
                      onClick={() => handleEditarAnuncio(anuncio)}
                      style={{ color: '#FF6600' }}
                    />,
                    <Popconfirm
                      title="Tem certeza que deseja excluir este anúncio?"
                      onConfirm={() => handleExcluirAnuncio(anuncio.id)}
                      okText="Sim"
                      cancelText="Não"
                      okButtonProps={{ style: buttonStyle }}
                    >
                      <DeleteOutlined key="delete" style={{ color: '#FF6600' }} />
                    </Popconfirm>,
                  ]}
                >
                  {/* Conteúdo do card permanece o mesmo */}
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Title level={4} style={{ 
                      margin: 0, 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap' 
                    }}>
                      {anuncio.titulo}
                    </Title>
                    <Space size="small" align="center">
                      <Tag color="purple">{anuncio.codigo}</Tag>
                      <Tag color={anuncio.tipoanuncio === 'Peca' ? 'blue' : 'green'}>
                        {anuncio.tipoanuncio === 'Veiculo' ? 'Veículo' : 'Peça'}
                      </Tag>
                    </Space>
                    <Paragraph
                      ellipsis={{ rows: 3, expandable: true, symbol: 'mais' }}
                      style={{ maxHeight: '4.5em', overflow: 'hidden' }}
                    >
                      {anuncio.descricao}
                    </Paragraph>
                    <Space>
                      <DollarOutlined />
                      <span>R$ {parseFloat(anuncio.preco).toFixed(2)}</span>
                    </Space>
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>

      {/* Modal de Criar Novo Anúncio */}
      <Modal
        title="Novo Anúncio"
        visible={isCreateModalVisible}
        onCancel={handleCancelPublicar}
        maskClosable={false}
        width={800}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCancelPublicar}>
            Cancelar
          </Button>,
          <Button 
            key="random" 
            onClick={gerarDadosAleatorios}
            style={{ backgroundColor: '#722ed1', borderColor: '#722ed1', color: 'white' }}
          >
            Gerar Dados Aleatórios
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
            style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }}
          >
            Publicar
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpload}
        >
          {/* Seleção de Tipo de Anúncio */}
          <Form.Item
            name="tipoAnuncio"
            label="Tipo de Anúncio"
            rules={[{ required: true, message: 'Selecione o tipo de anúncio' }]}
          >
            <Select onChange={(value) => setTipoAnuncio(value)}>
              <Option value="Veiculo">Veículo</Option>
              <Option value="Peca">Peça</Option>
            </Select>
          </Form.Item>

          {/* Campos comuns */}
          <Form.Item
            name="titulo"
            label="Título"
            rules={[{ required: true, message: 'Digite o título do anúncio' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="descricao"
            label="Descrição"
            rules={[{ required: true, message: 'Digite a descrição do anúncio' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          {tipoAnuncio === 'Veiculo' && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="marca"
                    label="Marca"
                    rules={[{ required: true, message: 'Digite a marca' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="modelo"
                    label="Modelo"
                    rules={[{ required: true, message: 'Digite o modelo' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="ano"
                    label="Ano"
                    rules={[{ required: true, message: 'Digite o ano' }]}
                  >
                    <InputNumber style={{ width: '100%' }} min={1900} max={2024} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="quilometragem"
                    label="Quilometragem"
                    rules={[{ required: true, message: 'Digite a quilometragem' }]}
                  >
                    <InputNumber style={{ width: '100%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="combustivel"
                    label="Combustível"
                  >
                    <Select>
                      <Option value="Gasolina">Gasolina</Option>
                      <Option value="Álcool">Álcool</Option>
                      <Option value="Flex">Flex</Option>
                      <Option value="Diesel">Diesel</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Form.Item
            name="preco"
            label="Preço"
            rules={[{ required: true, message: 'Digite o preço' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={value => value.replace(/R\$\s?|(\.*)/g, '')}
            />
          </Form.Item>

          {/* Upload de Fotos */}
          <Form.Item label="Fotos">
            <Upload
              listType="picture-card"
              fileList={[
                ...fotosUrls.map((url, index) => ({
                  uid: `existing-${index}`,
                  name: `foto-${index}`,
                  status: 'done',
                  url: url,
                })),
                ...fotosParaUpload
              ]}
              onRemove={(file) => {
                if (file.url) {
                  setFotosUrls(fotosUrls.filter(url => url !== file.url));
                } else {
                  setFotosParaUpload(fotosParaUpload.filter(f => f.uid !== file.uid));
                }
                return true;
              }}
              beforeUpload={(file) => {
                setFotosParaUpload(prev => [...prev, file]);
                return false;
              }}
            >
              {fotosUrls.length + fotosParaUpload.length >= 8 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload (Máx: 8)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal de Editar Anúncio */}
      <Modal
        title="Editar Anúncio"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        width={800}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleModalOk}
            style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }}
          >
            Atualizar
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={editFormData}
          onFinish={handleModalOk}
        >
          {/* Seleção de Tipo de Anúncio */}
          <Form.Item
            name="tipoAnuncio"
            label="Tipo de Anúncio"
            rules={[{ required: true, message: 'Selecione o tipo de anúncio' }]}
          >
            <Select onChange={(value) => setTipoAnuncio(value)} disabled>
              <Option value="Veiculo">Veículo</Option>
              <Option value="Peca">Peça</Option>
            </Select>
          </Form.Item>

          {/* Campos comuns */}
          <Form.Item
            name="titulo"
            label="Título"
            rules={[{ required: true, message: 'Digite o título do anúncio' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="descricao"
            label="Descrição"
            rules={[{ required: true, message: 'Digite a descrição do anúncio' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          {tipoAnuncio === 'Veiculo' && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="marca"
                    label="Marca"
                    rules={[{ required: true, message: 'Digite a marca' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="modelo"
                    label="Modelo"
                    rules={[{ required: true, message: 'Digite o modelo' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="ano"
                    label="Ano"
                    rules={[{ required: true, message: 'Digite o ano' }]}
                  >
                    <InputNumber 
                      style={{ width: '100%' }} 
                      min={1900} 
                      max={2024}
                      parser={value => value ? parseInt(value) : null}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="quilometragem"
                    label="Quilometragem"
                    rules={[{ required: true, message: 'Digite a quilometragem' }]}
                  >
                    <InputNumber 
                      style={{ width: '100%' }} 
                      min={0}
                      parser={value => value ? parseInt(value) : null}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="combustivel"
                    label="Combustível"
                  >
                    <Select>
                      <Option value="Gasolina">Gasolina</Option>
                      <Option value="Álcool">Álcool</Option>
                      <Option value="Flex">Flex</Option>
                      <Option value="Diesel">Diesel</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Form.Item
            name="preco"
            label="Preço"
            rules={[{ required: true, message: 'Digite o preço' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={value => value ? parseFloat(value.replace(/R\$\s?|[.]/g, '').replace(',', '.')) : null}
            />
          </Form.Item>

          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Selecione o status' }]}
          >
            <Select>
              <Option value="ativo">Ativo</Option>
              <Option value="inativo">Inativo</Option>
              <Option value="vendido">Vendido</Option>
            </Select>
          </Form.Item>

          {/* Upload de Fotos */}
          <Form.Item label="Fotos">
            <Upload
              listType="picture-card"
              fileList={[
                ...fotosUrls.map((url, index) => ({
                  uid: `existing-${index}`,
                  name: `foto-${index}`,
                  status: 'done',
                  url: url,
                })),
                ...fotosParaUpload
              ]}
              onRemove={(file) => {
                if (file.url) {
                  setFotosUrls(fotosUrls.filter(url => url !== file.url));
                } else {
                  setFotosParaUpload(fotosParaUpload.filter(f => f.uid !== file.uid));
                }
                return true;
              }}
              beforeUpload={(file) => {
                setFotosParaUpload(prev => [...prev, file]);
                return false;
              }}
            >
              {fotosUrls.length + fotosParaUpload.length >= 8 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload (Máx: 8)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default PublicarAnuncio;
