import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Typography, InputNumber, Switch, Spin, Row, Col, Card } from 'antd';
import axios from 'axios';

const { Title } = Typography;

const Configuracoes = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [configuracoes, setConfiguracoes] = useState({
    taxaAnuncioBasico: 0,
    taxaAnuncioPremium: 0,
    comissaoVenda: 0,
    maximoFotos: 0,
    maximoCaracteresDescricao: 0,
    categoriasPermitidas: [], // Array de strings
    anunciosAutomaticamenteAprovados: false,
  });

  useEffect(() => {
    buscarConfiguracoes();
  }, []);

  const buscarConfiguracoes = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/configuracoes', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setConfiguracoes(response.data);
    } catch (error) {
      message.error('Erro ao buscar configurações.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await axios.put(process.env.REACT_APP_API_URL + '/api/configuracoes', values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Configurações atualizadas com sucesso!');
      buscarConfiguracoes(); // Atualiza as configurações após salvar
    } catch (error) {
      message.error('Erro ao atualizar configurações.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Title level={2}>Configurações do Sistema</Title>
      <Spin spinning={loading}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Card title="Taxas">
              <Form.Item
                label="Taxa Anúncio Básico"
                name="taxaAnuncioBasico"
                rules={[{ required: true, message: 'Por favor, insira a taxa para anúncios básicos!' }]}
              >
                <InputNumber
                  formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <Form.Item
                label="Taxa Anúncio Premium"
                name="taxaAnuncioPremium"
                rules={[{ required: true, message: 'Por favor, insira a taxa para anúncios premium!' }]}
              >
                <InputNumber
                  formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card title="Comissões">
              <Form.Item
                label="Comissão por Venda"
                name="comissaoVenda"
                rules={[{ required: true, message: 'Por favor, insira a comissão por venda!' }]}
              >
                <InputNumber
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <Card title="Regras de Publicação">
              <Form.Item
                label="Máximo de Fotos por Anúncio"
                name="maximoFotos"
                rules={[{ required: true, message: 'Por favor, insira o número máximo de fotos!' }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Máximo de Caracteres na Descrição"
                name="maximoCaracteresDescricao"
                rules={[{ required: true, message: 'Por favor, insira o número máximo de caracteres!' }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Categorias Permitidas (separadas por vírgula)"
                name="categoriasPermitidas"
                rules={[{ required: true, message: 'Por favor, insira as categorias permitidas!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Aprovar Anúncios Automaticamente" name="anunciosAutomaticamenteAprovados" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '1rem' }}>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Salvar Configurações
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Configuracoes;