import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, DatePicker, Typography, Row, Col, Card, Divider } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, CalendarOutlined, PhoneOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, Link } from 'react-router-dom';
import logo from '../components/img/logo_transparente.png';

const { Title, Text } = Typography;

const RegisterPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values.dataNascimento = values.dataNascimento ? moment(values.dataNascimento).format('YYYY-MM-DD') : null;
      await axios.post(process.env.REACT_APP_API_URL + '/api/vendedores/registrar', values);
      message.success('Conta criada com sucesso! Verifique seu email para ativar sua conta.');
      navigate('/login');
    } catch (error) {
      message.error(error.response?.data?.message || 'Erro ao criar conta.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row 
      justify="center" 
      align="middle" 
      style={{ 
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #001529 0%, #003366 100%)',
        padding: '20px'
      }}
    >
      <Col xs={23} sm={20} md={12} lg={8}>
        <div style={{
          opacity: fadeIn ? 1 : 0,
          transform: `translateY(${fadeIn ? 0 : '20px'})`,
          transition: 'all 0.5s ease-in-out',
        }}>
          <Card
            hoverable
            style={{
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              background: 'rgba(255, 255, 255, 0.95)',
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <img 
                src={logo} 
                alt="Logo" 
                style={{ 
                  width: '150px', 
                  marginBottom: '20px',
                  objectFit: 'contain',
                  height: 'auto'
                }} 
              />
              <Title 
                level={2} 
                style={{ 
                  fontFamily: 'Poppins, sans-serif',
                  color: '#001529',
                  marginBottom: '5px'
                }}
              >
                Criar nova conta
              </Title>
              <Text type="secondary">
                Preencha os dados abaixo para se cadastrar
              </Text>
            </div>

            <Form 
              form={form} 
              name="register" 
              onFinish={onFinish} 
              layout="vertical" 
              size="large"
              scrollToFirstError
            >
              <Form.Item
                name="nome"
                rules={[{ required: true, message: 'Por favor, insira seu nome completo!' }]}
              >
                <Input 
                  prefix={<UserOutlined style={{ color: '#bfbfbf' }} />} 
                  placeholder="Nome Completo"
                />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Por favor, insira seu email!' },
                  { type: 'email', message: 'Por favor, insira um email válido!' },
                ]}
              >
                <Input 
                  prefix={<MailOutlined style={{ color: '#bfbfbf' }} />} 
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="dataNascimento"
              >
                <DatePicker 
                  format="DD/MM/YYYY" 
                  placeholder="Data de Nascimento"
                  style={{ width: '100%' }}
                  suffixIcon={<CalendarOutlined style={{ color: '#bfbfbf' }} />}
                />
              </Form.Item>

              <Form.Item
                name="contato"
                rules={[{ required: true, message: 'Por favor, insira seu número de contato!' }]}
              >
                <Input 
                  prefix={<PhoneOutlined style={{ color: '#bfbfbf' }} />} 
                  placeholder="Contato"
                />
              </Form.Item>

              <Form.Item
                name="senha"
                rules={[
                  { required: true, message: 'Por favor, insira sua senha!' },
                  { min: 6, message: 'A senha deve ter pelo menos 6 caracteres!' },
                ]}
                hasFeedback
              >
                <Input.Password 
                  prefix={<LockOutlined style={{ color: '#bfbfbf' }} />} 
                  placeholder="Senha"
                />
              </Form.Item>

              <Form.Item
                name="confirmarSenha"
                dependencies={['senha']}
                rules={[
                  { required: true, message: 'Por favor, confirme sua senha!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('senha') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('As senhas não coincidem!'));
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password 
                  prefix={<LockOutlined style={{ color: '#bfbfbf' }} />} 
                  placeholder="Confirmar Senha"
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: '12px' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{
                    backgroundColor: '#FF6600',
                    borderColor: '#FF6600',
                    height: '45px',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    boxShadow: '0 2px 8px rgba(255, 102, 0, 0.2)',
                  }}
                  disabled={loading}
                >
                  {loading ? <LoadingOutlined style={{ color: 'white' }} /> : 'Criar Conta'}
                </Button>
              </Form.Item>

              <Divider style={{ margin: '24px 0' }}>
                <Text type="secondary">ou</Text>
              </Divider>

              <div style={{ textAlign: 'center' }}>
                <Text type="secondary">
                  Já tem uma conta?{' '}
                  <Link 
                    to="/login"
                    style={{ 
                      color: '#FF6600',
                      fontWeight: '500',
                      transition: 'color 0.3s'
                    }}
                    onMouseEnter={e => e.target.style.color = '#ff8533'}
                    onMouseLeave={e => e.target.style.color = '#FF6600'}
                  >
                    Fazer login
                  </Link>
                </Text>
              </div>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default RegisterPage;
