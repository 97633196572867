import React from 'react';
import './HeroSection.css';
import { CarOutlined, SafetyOutlined, DollarOutlined, CheckCircleOutlined } from '@ant-design/icons';

import Footer from './Footer';

function HeroSection() {
  return (
    <div className="hero-container">
      <div className="hero-wrapper">
        <div className="hero-image-container">
          <div className="hero-image-background"></div>
          <div className="hero-image"></div>
        </div>
        <div className="hero-content">
          <h1>Quem Somos?</h1>
          <o>A Ads Auto tem como conceito um classificado que explora o máximo dos recursos de marketing digital com foco em Google Ads e todas as suas funcionalidades. Uma vez que você anuncia com a gente seu anúncio ganha muito mais visibilidade nas buscas e redes sociais.
<p>Quais as Vantagens:</p>
<p>-Público Qualificado</p>
<p>-Maior exposição</p>
<p>- Maior conversão</p>
<p>- Planos Grátis</p>
<p>- Planos Avançados com resultados expressivos</p>
<p>Tenha mais resultados. Não temos limites de anúncio na versão grátis e você consegue alta exposição mesmo nesse plano.</p>
</o>
          <p>MVP é uma sigla em inglês que significa Minimum Viable Product, ou seja, "Produto Mínimo Viável" em português. No mundo dos negócios, o MVP é uma versão inicial de um produto que tem apenas as funcionalidades essenciais para ser lançado no mercado. O objetivo é validar o produto antes do lançamento, testando a reação do público e identificando pontos de melhoria.

Enfim temos nosso MVP rodando, nossa proposta é dar ao usuário a possibilidade de poder anunciar seus produtos e atingir o máximo de público qualificado possível através das ferramentas de publicidade online mais eficientes do mercado.</p>
<a href='https://forms.zohopublic.com/checkmindbrasil/form/ChecklistVeicular/formperma/jXzVoefybH4uGb_75pLF0_KNG0a5E0j_DpFJNdcgSVA'><button className="cta-button" >Anuncie aqui seu veículo.</button></a>
        </div>
      </div>

      <div className="features-section">
        <h2>Por que escolher nossa plataforma?</h2>
        <div className="features-grid">
          <div className="feature-item">
            <CarOutlined className="feature-icon" />
            <h3>Anúncios de Qualidade</h3>
            <p>Destaque seu veículo com anúncios detalhados e fotos de alta qualidade.</p>
          </div>
          <div className="feature-item">
            <SafetyOutlined className="feature-icon" />
            <h3>Compra Segura</h3>
            <p>Garantimos a segurança nas transações entre compradores e vendedores.</p>
          </div>
          <div className="feature-item">
            <DollarOutlined className="feature-icon" />
            <h3>Preços Competitivos</h3>
            <p>Encontre os melhores preços do mercado ou anuncie pelo valor justo.</p>
          </div>
          <div className="feature-item">
            <CheckCircleOutlined className="feature-icon" />
            <h3>Veículos Verificados</h3>
            <p>Todos os veículos passam por uma verificação de qualidade antes do anúncio.</p>
          </div>
        </div>
      </div>

      <div className="how-it-works-section">
        <h2>Como Funciona</h2>
        <div className="steps-container">
          <div className="step">
            <img src="./img/step1.svg" alt="Cadastre-se" />
            <div className="step-number">1</div>
            <h3>Cadastre-se</h3>
            <p>Crie sua conta gratuitamente em nossa plataforma.</p>
          </div>
          <div className="step">
            <img src="./img/step2.svg" alt="Anuncie seu Veículo" />
            <div className="step-number">2</div>
            <h3>Anuncie seu Veículo</h3>
            <p>Publique as informações e fotos do seu carro.</p>
          </div>
          <div className="step">
            <img src="./img/step3.svg" alt="Conecte-se com Compradores" />
            <div className="step-number">3</div>
            <h3>Conecte-se com Compradores</h3>
            <p>Receba mensagens de interessados diretamente.</p>
          </div>
          <div className="step">
            <img src="./img/step4.svg" alt="Feche o Negócio" />
            <div className="step-number">4</div>
            <h3>Feche o Negócio</h3>
            <p>Realize a venda de forma segura e prática.</p>
          </div>
        </div>
        <div className="timeline"></div>
      </div>

      <div className="cta-section">
        <h2>Pronto para começar?</h2>
        <p>Anuncie seu veículo agora e alcance milhares de compradores potenciais!</p>
        <button className="cta-button large">Criar Anúncio Grátis</button>
      </div>
     
    </div>
  );
}

export default HeroSection;