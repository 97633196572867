import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const BoasVindasAdmin = () => {
  return (
    <div>
      <Title level={2}>Bem-vindo, Administrador!</Title>
      <p>Esta é a área administrativa do sistema.</p>
    </div>
  );
};

export default BoasVindasAdmin;