import React, { useEffect } from 'react';

const GoogleAd = ({ slot, format = 'auto', responsive = true }) => {
  useEffect(() => {
    try {
      // Tenta carregar os anúncios quando o componente montar
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error('Erro ao carregar anúncio:', err);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="ca-pub-2909567457385400"
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={responsive}
    />
  );
};

export default GoogleAd;
