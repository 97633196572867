import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, Menu } from 'antd';
import { HomeOutlined, CarOutlined, InfoCircleOutlined, ShopOutlined, MenuOutlined } from '@ant-design/icons';
import './Header.css';
import logo from './img/logo_transparente.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="header-content">
        <div className="logo-container">
          <img src={logo} alt="ADS Auto Logo" className="logo" />
        </div>
        <nav className="desktop-menu">
          <ul>
            <li><Link to="/">Vitrine</Link></li>
            <li><Link to="/sobre-nos">Sobre Nós</Link></li>
            <li><Link to="/register">Criar Conta</Link></li> {/* Novo item */}
            <li><Link to="/login">Anunciar</Link></li> {/* Novo item */}
          </ul>
        </nav>
        <button className="menu-toggle" onClick={toggleMenu}>
          <MenuOutlined />
        </button>
        <Drawer
          title="Menu"
          placement="right"
          onClose={closeMenu}
          open={isMenuOpen}
          className="mobile-menu-drawer"
        >
          <Menu mode="vertical" onClick={closeMenu}>
            <Menu.Item key="vitrine" icon={<ShopOutlined />}>
              <Link to="/">Vitrine</Link>
            </Menu.Item>
            <Menu.Item key="sobre-nos" icon={<InfoCircleOutlined />}>
              <Link to="/sobre-nos">Sobre Nós</Link>
            </Menu.Item>
            <Menu.Item key="criar-conta" icon={<InfoCircleOutlined />}> {/* Novo item */}
              <Link to="/register">Criar Conta</Link>
            </Menu.Item>
            <Menu.Item key="anunciar" icon={<InfoCircleOutlined />}> {/* Novo item */}
              <Link to="/login">Anunciar</Link>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    </header>
  );
}

export default Header;