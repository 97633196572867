import React, { useState, useEffect } from 'react';
import {
  Table, Button, Modal, Form, Input, message, Space, Popconfirm, Select,
  Typography, InputNumber, Row, Col, Card, Upload,  Spin,
  Carousel, Avatar, Tag, Divider,
} from 'antd';
import {
  EditOutlined, DeleteOutlined,PlusOutlined, CarOutlined, DollarOutlined, UserOutlined,
  ToolOutlined, SearchOutlined, ShopOutlined,
} from '@ant-design/icons';
import axios from 'axios';

const { Title } = Typography;
const { Option } = Select;

const GerenciarAnunciosAdmin = () => {
  const [anuncios, setAnuncios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    titulo: '', descricao: '', marca: '', modelo: '', ano: null,
    preco: null, quilometragem: null, combustivel: '', cor: '',
    vendedorId: null, status: 'ativo',
    tipoAnuncio: null,
  });

  const [editingAnuncio, setEditingAnuncio] = useState(null);

  const [form] = Form.useForm();
  const [fotosUrls, setFotosUrls] = useState([]);
  const [fotosParaUpload, setFotosParaUpload] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    buscarAnuncios();
  }, []);

  const buscarAnuncios = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/anuncios', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const anunciosFormatados = response.data.map((anuncio) => ({
        ...anuncio,
        vendedorNome: anuncio.vendedor_nome,
        concessionariaNome: anuncio.concessionaria_nome,
      }));
      setAnuncios(anunciosFormatados);
    } catch (error) {
      console.error('Erro ao buscar anúncios:', error);
    } finally {
      setLoading(false);
    }
  };

  const editarAnuncio = async (anuncio) => {
    setEditingAnuncio(anuncio);
    
    setFormData({
      ...anuncio,
      tipoAnuncio: anuncio.tipoanuncio,
      vendedorId: anuncio.vendedorId,
    });

    setFotosUrls(anuncio.fotos || []);

    setIsModalVisible(true);

    setTimeout(() => {
      form.setFieldsValue({
        ...anuncio,
        tipoAnuncio: anuncio.tipoanuncio,
        vendedorId: anuncio.vendedorId,
      });
    }, 100);
  };

  const excluirAnuncio = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/anuncios/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Anúncio excluído com sucesso!');
      buscarAnuncios();
    } catch (error) {
      message.error('Erro ao excluir anúncio.');
      console.error(error);
    }
  };

  const handleRemovePhoto = (file) => {
    const index = fotosUrls.indexOf(file.url);
    if (index > -1) {
      setFotosUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
      setFotosParaUpload((prevFotos) => prevFotos.filter((f) => f.uid !== file.uid));
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const filteredAnuncios = anuncios.filter((anuncio) => {
    const matchesSearchTerm = anuncio.titulo.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory === null || anuncio.tipoanuncio === selectedCategory;
    return matchesSearchTerm && matchesCategory;
  });

  const columns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      key: 'codigo',
      sorter: (a, b) => a.codigo.localeCompare(b.codigo),
    },
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
    },
    {
      title: 'Categoria',
      dataIndex: 'tipoanuncio',
      key: 'tipoanuncio',
      sorter: (a, b) => a.tipoanuncio.localeCompare(b.tipoanuncio),
      render: (tipoanuncio) => (
        tipoanuncio === 'Veiculo' ? 'Veículo' : 'Peça' 
      ),
    },
    {
      title: 'Preo',
      dataIndex: 'preco',
      key: 'preco',
      sorter: (a, b) => a.preco - b.preco,
      render: (preco) => `R$ ${parseFloat(preco).toFixed(2)}`,
    },
    {
      title: 'Vendedor',
      dataIndex: 'vendedorNome',
      key: 'vendedor',
      sorter: (a, b) => (a.vendedorNome || '').localeCompare(b.vendedorNome || ''),
    },
    {
      title: 'Concessionária',
      dataIndex: 'concessionariaNome',
      key: 'concessionaria',
      sorter: (a, b) => (a.concessionariaNome || '').localeCompare(b.concessionariaNome || ''),
      render: (text) => (
        <>
          <ShopOutlined style={{ marginRight: '8px' }} />
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let color = 'default';
        if (status === 'ativo') {
          color = 'green';
        } else if (status === 'inativo') {
          color = 'red';
        } else if (status === 'vendido') {
          color = 'blue';
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (text, anuncio) => (
        <Space size="middle">
          <Button type="link" onClick={() => editarAnuncio(anuncio)}>
            Editar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir este anúncio?"
            onConfirm={() => excluirAnuncio(anuncio.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      titulo: '',
      descricao: '',
      marca: '',
      modelo: '',
      ano: null,
      preco: null,
      quilometragem: null,
      combustivel: '',
      cor: '',
      vendedorId: null,
      status: 'ativo',
      tipoAnuncio: null,
    });
    setFotosUrls([]);
    setFotosParaUpload([]);
    setUploadProgress({});
    setEditingAnuncio(null);
  };

  const handleUpload = async (values) => {
    try {
      setLoading(true);

      let fotosFinais = editingAnuncio ? editingAnuncio.fotos : [];

      if (fotosParaUpload.length > 0) {
        const novasFotos = await Promise.all(
          fotosParaUpload.map(async (foto) => {
            const formData = new FormData();
            formData.append('foto', foto);

            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/upload/foto`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [foto.uid]: percentCompleted,
                  }));
                },
              }
            );
            return response.data.url;
          })
        );
        fotosFinais = [...fotosFinais, ...novasFotos];
      }

      const anuncioData = {
        ...values,
        ano: parseInt(values.ano, 10) || 0,
        preco: parseFloat(values.preco) || 0,
        quilometragem: parseInt(values.quilometragem, 10) || 0,
        fotos: fotosFinais,
        tipoanuncio: values.tipoAnuncio || 'Veiculo',
        vendedorId: values.vendedorId
      };

      if (editingAnuncio) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/anuncios/${editingAnuncio.id}`,
          anuncioData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        message.success('Anúncio atualizado com sucesso!');
      } else {
        await axios.post(process.env.REACT_APP_API_URL + '/api/anuncios', anuncioData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success(`Anúncio de ${formData.tipoAnuncio === 'Veiculo' ? 'veículo' : 'peça'} criado com sucesso!`);
      }

      handleCancel();
      buscarAnuncios();
    } catch (error) {
      console.error('Erro ao salvar anúncio:', error);
      message.error('Erro ao salvar anúncio.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Gerenciar Anúncios (Admin)</Title>

          <Row justify="center" style={{ marginBottom: '1rem' }}>
            <Col xs={24} sm={20} md={16} lg={12}>
              <Input.Search
                placeholder="Pesquisar anúncios..."
                value={searchTerm}
                onChange={handleSearchChange}
                enterButton
                size="large"
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginBottom: '1rem' }}>
            <Col xs={24} sm={20} md={16} lg={12}>
              <Select
                placeholder="Filtrar por categoria"
                onChange={handleCategoryChange}
                value={selectedCategory}
                size="large"
                style={{ width: '100%' }}
                allowClear
              >
                <Option value="Veiculo">Veículo</Option>
                <Option value="Peca">Peça</Option>
              </Select>
            </Col>
          </Row>

          <Divider />

          <Row gutter={[16, 16]}>
            {filteredAnuncios.map((anuncio) => (
              <Col xs={24} sm={12} md={8} lg={6} key={anuncio.id} style={{ marginBottom: '1rem' }}>
                <Card
                  cover={
                    anuncio.fotos && anuncio.fotos.length > 0 ? (
                      <img
                        src={anuncio.fotos[0]}
                        alt={`Foto ${anuncio.titulo}`}
                        style={{ width: '100%', height: 200, objectFit: 'cover' }}
                      />
                    ) : (
                      <div style={{ width: '100%', height: 200, background: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Avatar size={64} icon={anuncio.tipoanuncio === 'Peca' ? <ToolOutlined /> : <CarOutlined />} />
                      </div>
                    )
                  }
                  actions={[
                    <EditOutlined key="edit" onClick={() => editarAnuncio(anuncio)} />,
                    <Popconfirm
                      key="delete"
                      title="Tem certeza que deseja excluir este anúncio?"
                      onConfirm={() => excluirAnuncio(anuncio.id)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <DeleteOutlined />
                    </Popconfirm>,
                  ]}
                >
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Title level={4} style={{ margin: 0 }}>{anuncio.titulo}</Title>
                    <Tag color="purple">{anuncio.codigo}</Tag>
                    <Tag color={anuncio.tipoanuncio === 'Peca' ? 'blue' : 'green'}>
                      {anuncio.tipoanuncio === 'Veiculo' ? 'Veículo' : 'Peça'}
                    </Tag>
                    <Space>
                      <DollarOutlined />
                      <span>R$ {parseFloat(anuncio.preco).toFixed(2)}</span>
                    </Space>
                    <Space>
                      <UserOutlined />
                      <span>{anuncio.vendedorNome}</span>
                    </Space>
                    <span>{anuncio.concessionariaNome}</span>
                    <Tag color={anuncio.status === 'ativo' ? 'green' : anuncio.status === 'inativo' ? 'red' : 'blue'}>
                      {anuncio.status}
                    </Tag>
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>

      <Modal
        title={formData.id ? 'Editar Anúncio' : 'Novo Anúncio'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()} loading={loading}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="anuncioForm"
          initialValues={formData}
          onFinish={handleUpload}
          layout="vertical"
        >
          <Form.Item
            label="Título"
            name="titulo"
            rules={[{ required: true, message: 'Por favor, insira o título do anúncio!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="descricao">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Marca"
            name="marca"
            rules={[{ required: true, message: 'Por favor, insira a marca!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Modelo"
            name="modelo"
            rules={[{ required: true, message: 'Por favor, insira o modelo!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ano"
            name="ano"
            rules={[{ required: true, message: 'Por favor, insira o ano!' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Preço"
            name="preco"
            rules={[{ required: true, message: 'Por favor, insira o preço!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/R\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label="Quilometragem"
            name="quilometragem"
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="Combustível"
            name="combustivel"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cor"
            name="cor"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de Anúncio"
            name="tipoAnuncio"
            rules={[{ required: true, message: 'Por favor, selecione o tipo de anúncio!' }]}
          >
            <Select>
              <Option value="Veiculo">Veículo</Option>
              <Option value="Peca">Peça</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[{ required: true, message: 'Por favor, selecione o status!' }]}
          >
            <Select>
              <Option value="ativo">Ativo</Option>
              <Option value="inativo">Inativo</Option>
              <Option value="vendido">Vendido</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Fotos" name="fotos">
            <Upload
              listType="picture-card"
              fileList={[...fotosUrls.map((url, index) => ({
                uid: `-${index}`,
                name: `foto-${index + 1}.jpg`,
                status: 'done',
                url: url,
              })), ...fotosParaUpload]}
              onRemove={handleRemovePhoto}
              beforeUpload={(file) => {
                setFotosParaUpload((prev) => [...prev, file]);
                return false;
              }}
            >
              {fotosUrls.length + fotosParaUpload.length < 5 && (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default GerenciarAnunciosAdmin;