import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Dashboard from './pages/Dashboard';
import BoasVindasAdmin from './pages/BoasVindasAdmin';
import BoasVindasUsuario from './pages/BoasVindasUsuario';
import AdminDashboard from './pages/Admin/Dashboard';
import GerenciarAdministradores from './pages/Admin/GerenciarAdministradores';
import GerenciarConcessionarias from './pages/Admin/GerenciarConcessionarias';
import GerenciarVendedores from './pages/Admin/GerenciarVendedores';
import GerenciarAnuncios from './pages/Admin/GerenciarAnuncios';
import Relatorios from './pages/Admin/Relatorios';
import Configuracoes from './pages/Admin/Configuracoes';
import GerenciarAnunciosConcessionaria from './pages/Concessionaria/GerenciarAnuncios';
import GerenciarVendedoresConcessionaria from './pages/Concessionaria/GerenciarVendedores';
import ConcessionariaDashboard from './pages/Concessionaria/Dashboard';
import MeusAnuncios from './pages/Usuario/MeusAnuncios';
import Perfil from './pages/Usuario/Perfil';
import PublicarAnuncio from './pages/Usuario/PublicarAnuncio';
import SolicitarConcessionaria from './pages/Usuario/SolicitarConcessionaria';
import VerificarConta from './pages/Usuario/VerificarConta';
import HeroSection from './components/HeroSection';
import Vitrine from './components/Vitrine';
import AddressButton from './components/AddressButton';
import AdsAutoLayout from './layouts/AdsAutoLayout'; // Importe o layout
import { jwtDecode } from 'jwt-decode';
import './App.css';
import PrivateRoute from './components/PrivateRoute';

function App() {
  const [usuario, setUsuario] = useState(null);

  return (
    <Router>
      <Routes>
        {/* Rotas do Ads Auto (fora da rota /dashboard) */}
        <Route element={<AdsAutoLayout />}>
          <Route path="/" element={<Vitrine />} />
          <Route path="/sobre-nos" element={<HeroSection />} />
        </Route>

        {/* Rotas públicas */}
        <Route path="/login" element={<LoginPage setUsuario={setUsuario} />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verificar-conta/:token" element={<VerificarConta />} />

        {/* Rotas privadas (requerem autenticação) */}
        <Route path="/dashboard" element={
          <PrivateRoute>
            <Dashboard usuario={usuario} />
          </PrivateRoute>
        }>
          {/* Rotas do Admin */}
          <Route path="admin" element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }>
            <Route index element={<AdminDashboard />} />
            <Route path="gerenciar-administradores" element={<GerenciarAdministradores />} />
            <Route path="gerenciar-concessionarias" element={<GerenciarConcessionarias />} />
            <Route path="gerenciar-vendedores" element={<GerenciarVendedores />} />
            <Route path="gerenciar-anuncios" element={<GerenciarAnuncios />} />
            <Route path="relatorios" element={<Relatorios />} />
            <Route path="configuracoes" element={<Configuracoes />} />
          </Route>

          {/* Rotas da Concessionária */}
          <Route path="concessionaria" element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }>
            <Route index element={<ConcessionariaDashboard />} />
            <Route path="gerenciar-anuncios" element={<GerenciarAnunciosConcessionaria />} />
            <Route path="gerenciar-vendedores" element={<GerenciarVendedoresConcessionaria />} />
          </Route>

          {/* Rotas do Usuário (Vendedor) */}
          <Route path="usuario" element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }>
            <Route path="perfil" element={<Perfil />} />
            <Route path="meus-anuncios" element={<MeusAnuncios />} />
            <Route path="publicar-anuncio" element={<PublicarAnuncio />} />
            <Route path="solicitar-concessionaria" element={<SolicitarConcessionaria />} />
            <Route index element={<Navigate to="publicar-anuncio" />} />
          </Route>
        </Route>

        {/* Redirecionar para / para qualquer outra rota não correspondida */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;