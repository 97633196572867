import React, { useState, useEffect } from 'react';
import { Card, Avatar, Button } from 'antd';
import { 
  CarOutlined, 
  ToolOutlined, 
  DollarOutlined,
  ShopOutlined,
  CalendarOutlined,
  TagOutlined,
  UserOutlined,
  WhatsAppOutlined,
  PictureOutlined
} from '@ant-design/icons';
import './VehicleCard.css';

const VehicleCard = ({ vehicle, onOpenModal }) => {
  const [imgError, setImgError] = useState(false);
  const [concessionariaImgError, setConcessionariaImgError] = useState(false);

  console.log('Dados do veículo no card:', {
    id: vehicle.id,
    vendedor: vehicle.vendedor,
    vendedor_foto: vehicle.vendedor_foto,
    concessionaria: vehicle.concessionaria,
    concessionaria_foto: vehicle.concessionaria_foto
  });

  const formatarPreco = (preco) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(preco);
  };

  const handleWhatsAppClick = (e) => {
    e.stopPropagation(); // Previne que o modal abra quando clicar no botão
    
    const phoneNumber = vehicle.vendedor_contato?.replace(/\D/g, '') || '';
    const formattedPhone = phoneNumber.startsWith('55') ? phoneNumber : `55${phoneNumber}`;
    
    if (!formattedPhone) {
      message.error('Número de contato do vendedor não disponível');
      return;
    }

    const message = `Olá! Vi seu anúncio do ${vehicle.name} (Código: ${vehicle.codigo}) no site e gostaria de mais informações.`;
    const whatsappUrl = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Card
      className="vehicle-card"
      cover={
        vehicle.images && vehicle.images.length > 0 ? (
          <img 
            alt={vehicle.name}
            src={vehicle.images[0]}
            className="vehicle-image"
          />
        ) : (
          <div className="no-image">Sem imagem</div>
        )
      }
    >
      <div className="card-header">
        <h3>{vehicle.name}</h3>
        {vehicle.tipoanuncio === 'Peca' ? <ToolOutlined /> : <CarOutlined />}
      </div>
      
      <p className="price" style={{ color: '#FF6600' }}>
        <DollarOutlined /> {formatarPreco(vehicle.price)}
      </p>
      
      <p className="description">{vehicle.description}</p>
      
      <div className="details">
        <p><CalendarOutlined /> Ano: {vehicle.year}</p>
        <p><TagOutlined /> Marca: {vehicle.manufacturer}</p>
        <p><TagOutlined /> Modelo: {vehicle.model}</p>
        <p><TagOutlined /> Código: {vehicle.codigo}</p>
        
        <Button 
          type="primary"
          icon={<PictureOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onOpenModal(vehicle);
          }}
          className="details-button"
          block
        >
          Ver Fotos e Detalhes
        </Button>

        <Button 
          type="primary"
          icon={<WhatsAppOutlined />}
          onClick={handleWhatsAppClick}
          className="whatsapp-button"
          block
        >
          Contatar Vendedor
        </Button>
      </div>
      
      <div className="vendor-section">
        <div className="vendor-info">
          <div className="vendor-header">
            <UserOutlined /> Vendedor
          </div>
          <div className="vendor-details">
            {!imgError && vehicle.vendedor_foto ? (
              <img 
                src={vehicle.vendedor_foto}
                alt={`Foto de ${vehicle.vendedor}`}
                className="vendor-photo"
                onError={() => setImgError(true)}
              />
            ) : (
              <Avatar icon={<UserOutlined />} />
            )}
            <span>{vehicle.vendedor}</span>
          </div>
        </div>

        {vehicle.concessionaria && (
          <div className="dealer-info">
            <div className="dealer-header">
              <ShopOutlined /> Concessionária
            </div>
            <div className="dealer-details">
              {!concessionariaImgError && vehicle.concessionaria_foto ? (
                <img 
                  src={vehicle.concessionaria_foto}
                  alt={`Logo da ${vehicle.concessionaria}`}
                  className="dealer-photo"
                  onError={() => setConcessionariaImgError(true)}
                />
              ) : (
                <Avatar icon={<ShopOutlined />} style={{ backgroundColor: '#FF6600' }} />
              )}
              <span>{vehicle.concessionaria}</span>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

export default VehicleCard;