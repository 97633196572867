import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Row, 
  Col, 
  Card, 
  Statistic, 
  Table, 
  Progress, 
  Space,
  Select,
  DatePicker,
  Tag,
  message
} from 'antd';
import {
  CarOutlined,
  TeamOutlined,
  DollarOutlined,
  RiseOutlined,
  ShopOutlined,
  TagOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const ConcessionariaDashboard = () => {
  const [estatisticas, setEstatisticas] = useState({
    totalAnuncios: 0,
    totalVendedores: 0,
    totalAnunciosVendidos: 0,
    anunciosAtivos: 0,
    anunciosPendentes: 0,
    vendasMensais: 0,
    desempenhoVendedores: [],
    anunciosRecentes: [],
    distribuicaoAnuncios: {
      veiculos: 0,
      pecas: 0
    }
  });
  const [periodoSelecionado, setPeriodoSelecionado] = useState('mes');
  const [concessionariaId, setConcessionariaId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setConcessionariaId(decodedToken.userId);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (concessionariaId) {
      buscarEstatisticas();
    }
  }, [concessionariaId]);

  const buscarEstatisticas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `/api/relatorios/concessionaria/${concessionariaId}`,
        {
          params: {
            periodo: periodoSelecionado
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      
      if (response.data) {
        setEstatisticas(response.data);
      } else {
        console.error('Dados não encontrados');
        message.error('Erro ao carregar estatísticas');
      }
    } catch (error) {
      console.error('Erro ao buscar estatísticas:', error);
      message.error('Erro ao carregar estatísticas');
    }
  };

  const colunasVendedores = [
    {
      title: 'Vendedor',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Anúncios Ativos',
      dataIndex: 'anunciosAtivos',
      key: 'anunciosAtivos',
      sorter: (a, b) => a.anunciosAtivos - b.anunciosAtivos,
    },
    {
      title: 'Vendas Realizadas',
      dataIndex: 'vendasRealizadas',
      key: 'vendasRealizadas',
      sorter: (a, b) => a.vendasRealizadas - b.vendasRealizadas,
    },
    {
      title: 'Taxa de Conversão',
      dataIndex: 'taxaConversao',
      key: 'taxaConversao',
      render: (taxa) => (
        <Progress 
          percent={taxa} 
          size="small" 
          status="active"
          strokeColor={{
            '0%': '#FF6600',
            '100%': '#87d068',
          }}
        />
      ),
    },
  ];

  const colunasAnuncios = [
    {
      title: 'Título',
      dataIndex: 'titulo',
      key: 'titulo',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      render: (tipo) => (
        <Tag color={tipo === 'Veículo' ? 'blue' : 'green'}>
          {tipo}
        </Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Ativo' ? 'success' : 'warning'}>
          {status}
        </Tag>
      ),
    },
    {
      title: 'Preço',
      dataIndex: 'preco',
      key: 'preco',
      render: (preco) => `R$ ${preco.toFixed(2)}`,
    },
  ];

  return (
    <div style={{ padding: '24px' }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {/* Cabeçalho com Filtros */}
        <Row justify="space-between" align="middle">
          <Title level={2}>Estatísticas Gerais</Title>
          <Space>
            <Select
              defaultValue="mes"
              style={{ width: 120 }}
              onChange={setPeriodoSelecionado}
              options={[
                { value: 'dia', label: 'Hoje' },
                { value: 'semana', label: 'Esta Semana' },
                { value: 'mes', label: 'Este Mês' },
                { value: 'ano', label: 'Este Ano' },
              ]}
            />
            <RangePicker />
          </Space>
        </Row>

        {/* Cards de Estatísticas */}
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} lg={6}>
            <Card hoverable>
              <Statistic
                title="Total de Anúncios"
                value={estatisticas.totalAnuncios}
                prefix={<CarOutlined style={{ color: '#FF6600' }} />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card hoverable>
              <Statistic
                title="Vendedores Ativos"
                value={estatisticas.totalVendedores}
                prefix={<TeamOutlined style={{ color: '#FF6600' }} />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card hoverable>
              <Statistic
                title="Anúncios Vendidos"
                value={estatisticas.totalAnunciosVendidos}
                prefix={<DollarOutlined style={{ color: '#FF6600' }} />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <Card hoverable>
              <Statistic
                title="Vendas Mensais"
                value={estatisticas.vendasMensais}
                prefix={<RiseOutlined style={{ color: '#FF6600' }} />}
                suffix="% vs. mês anterior"
              />
            </Card>
          </Col>
        </Row>

        {/* Distribuição de Anúncios com verificação de segurança */}
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Card title="Distribuição de Anúncios" hoverable>
              <Row justify="space-around">
                <Col>
                  <Statistic
                    title="Veículos"
                    value={estatisticas?.distribuicaoAnuncios?.veiculos || 0}
                    prefix={<CarOutlined style={{ color: '#FF6600' }} />}
                  />
                </Col>
                <Col>
                  <Statistic
                    title="Peças"
                    value={estatisticas?.distribuicaoAnuncios?.pecas || 0}
                    prefix={<TagOutlined style={{ color: '#FF6600' }} />}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Status dos Anúncios" hoverable>
              <Row justify="space-around">
                <Col>
                  <Statistic
                    title="Ativos"
                    value={estatisticas?.anunciosAtivos || 0}
                    prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                  />
                </Col>
                <Col>
                  <Statistic
                    title="Pendentes"
                    value={estatisticas?.anunciosPendentes || 0}
                    prefix={<ClockCircleOutlined style={{ color: '#faad14' }} />}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Tabelas com verificação de dados */}
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Card title="Desempenho dos Vendedores" hoverable>
              <Table
                dataSource={estatisticas?.desempenhoVendedores || []}
                columns={colunasVendedores}
                pagination={{ pageSize: 5 }}
                size="small"
                loading={!estatisticas?.desempenhoVendedores}
              />
            </Card>
          </Col>
          <Col xs={24} lg={12}>
            <Card title="Anúncios Recentes" hoverable>
              <Table
                dataSource={estatisticas?.anunciosRecentes || []}
                columns={colunasAnuncios}
                pagination={{ pageSize: 5 }}
                size="small"
                loading={!estatisticas?.anunciosRecentes}
              />
            </Card>
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default ConcessionariaDashboard;