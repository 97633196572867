import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Select,
  Typography,
  DatePicker,
  Row,
  Col,
  Card,
} from 'antd';
import axios from 'axios';
import moment from 'moment';

const { Title } = Typography;
const { Option } = Select;

const GerenciarVendedores = () => {
  const [vendedores, setVendedores] = useState([]);
  const [concessionarias, setConcessionarias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    nome: '',
    dataNascimento: null,
    contato: '',
    email: '',
    senha: '',
    concessionariaId: null,
    status: 'ativo',
  });
  const [form] = Form.useForm();

  useEffect(() => {
    buscarVendedores();
    buscarConcessionarias();
  }, []);

  const buscarVendedores = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/vendedores', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setVendedores(response.data);
    } catch (error) {
      message.error('Erro ao buscar vendedores.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const buscarConcessionarias = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/concessionarias', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setConcessionarias(response.data);
    } catch (error) {
      message.error('Erro ao buscar concessionárias.');
      console.error(error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      id: null,
      nome: '',
      dataNascimento: null,
      contato: '',
      email: '',
      senha: '',
      concessionariaId: null,
      status: 'ativo',
    });
  };

  const onFinish = async (values) => {
    try {
      // Formata a data de nascimento antes de enviar
      values.dataNascimento = values.dataNascimento ? moment(values.dataNascimento).format('YYYY-MM-DD') : null;

      if (formData.id) {
        // Editar vendedor
        await axios.put(process.env.REACT_APP_API_URL + `/api/vendedores/${formData.id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Vendedor atualizado com sucesso!');
      } else {
        // Criar vendedor
        await axios.post(process.env.REACT_APP_API_URL + '/api/vendedores', values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Vendedor criado com sucesso!');
      }
      handleCancel();
      buscarVendedores();
    } catch (error) {
      message.error('Erro ao salvar vendedor.');
      console.error(error);
    }
  };

  const editarVendedor = (vendedor) => {
    setFormData({
      ...vendedor,
      dataNascimento: vendedor.dataNascimento ? moment(vendedor.dataNascimento) : null,
    });
    showModal();
  };

  const excluirVendedor = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/vendedores/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Vendedor excluído com sucesso!');
      buscarVendedores();
    } catch (error) {
      message.error('Erro ao excluir vendedor.');
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Concessionária',
      dataIndex: ['concessionaria', 'nome'],
      key: 'concessionaria',
      sorter: (a, b) => a.concessionaria.nome.localeCompare(b.concessionaria.nome),
      filters: concessionarias.map((concessionaria) => ({
        text: concessionaria.nome,
        value: concessionaria.id,
      })),
      onFilter: (value, record) => record.concessionariaId === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Ativo', value: 'ativo' },
        { text: 'Inativo', value: 'inativo' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status) => {
        let color = status === 'ativo' ? 'green' : 'red';
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (text, vendedor) => (
        <Space size="middle">
          <Button type="link" onClick={() => editarVendedor(vendedor)}>
            Editar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir este vendedor?"
            onConfirm={() => excluirVendedor(vendedor.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Gerenciar Vendedores</Title>
          <Button type="primary" onClick={showModal} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
            Novo Vendedor
          </Button>
          <Table
            dataSource={vendedores}
            columns={columns}
            loading={loading}
            rowKey="id"
            style={{ marginTop: '1rem' }}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </Col>
      <Modal
        title={formData.id ? 'Editar Vendedor' : 'Novo Vendedor'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }} onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="vendedorForm"
          initialValues={formData}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Por favor, insira o nome do vendedor!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira o email!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Data de Nascimento"
            name="dataNascimento"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item
            label="Contato"
            name="contato"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Concessionária"
            name="concessionariaId"
            rules={[{ required: true, message: 'Por favor, selecione a concessionária!' }]}
          >
            <Select>
              {concessionarias.map((concessionaria) => (
                <Option key={concessionaria.id} value={concessionaria.id}>
                  {concessionaria.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Senha"
            name="senha"
            rules={formData.id ? [] : [{ required: true, message: 'Por favor, insira a senha!' }]}
          >
            <Input.Password />
          </Form.Item>
          {formData.id && (
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Por favor, selecione o status!' }]}
            >
              <Select>
                <Option value="ativo">Ativo</Option>
                <Option value="inativo">Inativo</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Row>
  );
};

export default GerenciarVendedores;