import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { message, Spin } from 'antd';

const VerificarConta = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificando, setVerificando] = useState(true);

  useEffect(() => {
    const verificarConta = async () => {
      setVerificando(true); // Inicia o loading
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/vendedores/verificar-conta`, { token });
        if (response.status === 200) {
          message.success('Conta verificada com sucesso!');
          setVerificando(false);
          setTimeout(() => navigate('/login?verificado=true'), 3000);
        } else {
          // Lida com erros de resposta que não sejam 200
          message.error('Erro ao verificar conta. Verifique o link ou tente novamente mais tarde.');
          setVerificando(false);
          setTimeout(() => navigate('/login'), 3000);
        }
      } catch (error) {
        console.error('Erro ao verificar conta:', error);
        if (error.response && error.response.data && error.response.data.message) {
          message.error(error.response.data.message); // Exibe a mensagem de erro do servidor, se disponível
        } else {
          message.error('Erro ao verificar conta. Verifique o link ou tente novamente mais tarde.');
        }
        setVerificando(false);
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    if (token) { // Verifica se o token existe antes de chamar a função
      verificarConta();
    } else {
      // Lida com o caso em que o token não está presente na URL
      message.error('Token de verificação ausente. Verifique o link de verificação.');
      setVerificando(false);
      setTimeout(() => navigate('/login'), 3000);
    }
  }, [token, navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      {verificando ? (
        <>
          <Spin size="large" />
          <p>Verificando sua conta...</p>
        </>
      ) : (
        <p>Redirecionando para a página de login...</p>
      )}
    </div>
  );
};

export default VerificarConta;