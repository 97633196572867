import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Select,
  Typography,
  Row,
  Col,
  Card,
} from 'antd';
import axios from 'axios';
import Chance from 'chance';

const { Title } = Typography;
const { Option } = Select;

const GerenciarConcessionarias = () => {
  const [concessionarias, setConcessionarias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    nome: '',
    cnpj: '',
    razaoSocial: '',
    email: '',
    senha: '',
    telefone: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    status: 'pendente',
  });
  const [form] = Form.useForm();

  useEffect(() => {
    buscarConcessionarias();
  }, []);

  const buscarConcessionarias = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/concessionarias', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setConcessionarias(response.data);
    } catch (error) {
      message.error('Erro ao buscar concessionárias.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      id: null,
      nome: '',
      cnpj: '',
      razaoSocial: '',
      email: '',
      senha: '',
      telefone: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: '',
      status: 'pendente',
    });
  };

  const onFinish = async (values) => {
    try {
      if (formData.id) {
        // Editar concessionária
        await axios.put(process.env.REACT_APP_API_URL + `/api/concessionarias/${formData.id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Concessionária atualizada com sucesso!');
      } else {
        // Criar concessionária
        await axios.post(process.env.REACT_APP_API_URL + '/api/concessionarias', values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Concessionária criada com sucesso!');
      }
      handleCancel();
      buscarConcessionarias();
    } catch (error) {
      message.error('Erro ao salvar concessionária.');
      console.error(error);
    }
  };

  const editarConcessionaria = (concessionaria) => {
    setFormData(concessionaria);
    showModal();
  };

  const excluirConcessionaria = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/concessionarias/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Concessionária excluída com sucesso!');
      buscarConcessionarias();
    } catch (error) {
      message.error('Erro ao excluir concessionária.');
      console.error(error);
    }
  };

  const gerarDadosAleatorios = () => {
    const chance = new Chance();

    // Gera dados aleatórios para o formulário
    const dadosAleatorios = {
      nome: chance.company(),
      cnpj: chance.cnpj(),
      razaoSocial: chance.company(),
      email: chance.email(),
      senha: chance.string({ length: 8 }),
      telefone: chance.phone(),
      logradouro: chance.address(),
      numero: chance.integer({ min: 1, max: 9999 }),
      complemento: chance.sentence({ words: 3 }),
      bairro: chance.city(),
      cidade: chance.city(),
      estado: chance.state(), // Corrigido: remove o parâmetro country ou usa 'BR'
    };

    form.setFieldsValue(dadosAleatorios);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      key: 'cnpj',
      sorter: (a, b) => a.cnpj.localeCompare(b.cnpj),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Pendente', value: 'pendente' },
        { text: 'Aprovada', value: 'aprovada' },
        { text: 'Recusada', value: 'recusada' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (status) => {
        let color = 'default';
        if (status === 'aprovada') {
          color = 'green';
        } else if (status === 'recusada') {
          color = 'red';
        }
        return <span style={{ color }}>{status}</span>;
      },
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (text, concessionaria) => (
        <Space size="middle">
          <Button type="link" onClick={() => editarConcessionaria(concessionaria)}>
            Editar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir esta concessionária?"
            description="Atenção: Todos os vendedores e anúncios associados a esta concessionária também serão excluídos."
            onConfirm={() => excluirConcessionaria(concessionaria.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Gerenciar Concessionárias</Title>
          <Button type="primary" onClick={showModal} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
            Nova Concessionária
          </Button>
          <Table
            dataSource={concessionarias}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </Col>
      <Modal
        title={formData.id ? 'Editar Concessionária' : 'Nova Concessionária'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="random" onClick={gerarDadosAleatorios}>
            Gerar Dados Aleatórios
          </Button>,
          <Button key="submit" type="primary" style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }} onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="concessionariaForm"
          initialValues={formData}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Por favor, insira o nome da concessionária!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[{ required: true, message: 'Por favor, insira o CNPJ!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Razão Social"
            name="razaoSocial"
            rules={[{ required: true, message: 'Por favor, insira a razão social!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira o email!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="senha"
            rules={formData.id ? [] : [{ required: true, message: 'Por favor, insira a senha!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Telefone"
            name="telefone"
            rules={[{ required: true, message: 'Por favor, insira o telefone!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Logradouro"
            name="logradouro"
            rules={[{ required: true, message: 'Por favor, insira o logradouro!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Número"
            name="numero"
            rules={[{ required: true, message: 'Por favor, insira o número!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Complemento" name="complemento">
            <Input />
          </Form.Item>
          <Form.Item
            label="Bairro"
            name="bairro"
            rules={[{ required: true, message: 'Por favor, insira o bairro!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Cidade"
            name="cidade"
            rules={[{ required: true, message: 'Por favor, insira a cidade!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Estado"
            name="estado"
            rules={[{ required: true, message: 'Por favor, insira o estado!' }]}
          >
            <Input />
          </Form.Item>
          {formData.id && ( // Exibe o campo status apenas na edição
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Por favor, selecione o status!' }]}
            >
              <Select>
                <Option value="pendente">Pendente</Option>
                <Option value="aprovada">Aprovada</Option>
                <Option value="recusada">Recusada</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Row>
  );
};

export default GerenciarConcessionarias;