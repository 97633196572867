import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const BoasVindasUsuario = () => {
  return (
    <div>
      <Title level={2}>Bem-vindo, Usuário!</Title>
      <p>Aqui você pode gerenciar seus anúncios de carros.</p>
    </div>
  );
};

export default BoasVindasUsuario;