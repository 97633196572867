import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Typography,
  DatePicker,
  Row,
  Col,
  Card,
  Select,
  Avatar,
  Divider
} from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Chance from 'chance';

const { Title } = Typography;
const { Option } = Select;

const GerenciarVendedoresConcessionaria = () => {
  const [vendedores, setVendedores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    dataNascimento: null,
    contato: '',
    email: '',
    senha: '',
    status: 'ativo',
  });
  const [form] = Form.useForm();
  const [concessionariaId, setConcessionariaId] = useState(null);
  const primeiraExecucao = useRef(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setConcessionariaId(decodedToken.userId);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (concessionariaId && primeiraExecucao.current) {
      primeiraExecucao.current = false;
      buscarVendedores();
    }
  }, [concessionariaId]);

  const buscarVendedores = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/api/vendedores/concessionaria/${concessionariaId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setVendedores(response.data);
    } catch (error) {
      message.error('Erro ao buscar vendedores.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      nome: '',
      dataNascimento: null,
      contato: '',
      email: '',
      senha: '',
      status: 'ativo',
    });
  };

  const onFinish = async (values) => {
    try {
      values.dataNascimento = values.dataNascimento ? moment(values.dataNascimento).format('YYYY-MM-DD') : null;
      const vendedorData = { ...values, concessionariaId };

      if (formData.id) {
        await axios.put(process.env.REACT_APP_API_URL + `/api/vendedores/${formData.id}`, vendedorData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Vendedor atualizado com sucesso!');
      } else {
        await axios.post(process.env.REACT_APP_API_URL + '/api/vendedores', vendedorData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Vendedor criado com sucesso!');
      }
      handleCancel();
      buscarVendedores();
    } catch (error) {
      message.error('Erro ao salvar vendedor.');
      console.error(error);
    }
  };

  const editarVendedor = (vendedor) => {
    setFormData({
      ...vendedor,
      dataNascimento: vendedor.dataNascimento ? moment(vendedor.dataNascimento) : null,
    });
    showModal();
  };

  const excluirVendedor = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/vendedores/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Vendedor excluído com sucesso!');
      buscarVendedores();
    } catch (error) {
      message.error('Erro ao excluir vendedor.');
      console.error(error);
    }
  };

  // Função para gerar dados aleatórios do vendedor
  const gerarVendedorAleatorio = () => {
    const chance = new Chance();

    // Gera dados aleatórios para o formulário
    const dadosAleatorios = {
      nome: chance.name(),
      dataNascimento: moment(chance.birthday({ string: true, format: 'YYYY-MM-DD' })),
      contato: chance.phone({ formatted: false }),
      email: chance.email(),
      senha: chance.string({ length: 8 }),
      status: 'ativo',
    };

    // Preenche os campos do formulário com os dados aleatórios
    form.setFieldsValue(dadosAleatorios);
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Gerenciar Vendedores</Title>
          <Button type="primary" onClick={showModal} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
            Novo Vendedor
          </Button>
          <Divider />

          {/* Lista de Cards para exibir os vendedores */}
          <Row gutter={[16, 16]}>
            {vendedores.map((vendedor) => (
              <Col xs={24} sm={12} md={8} lg={6} key={vendedor.id}>
                <Card
                  actions={[
                    <EditOutlined key="edit" onClick={() => editarVendedor(vendedor)} />,
                    <Popconfirm
                      title="Tem certeza que deseja excluir este vendedor?"
                      onConfirm={() => excluirVendedor(vendedor.id)}
                      okText="Sim"
                      cancelText="Não"
                      key="delete"
                    >
                      <DeleteOutlined key="delete" style={{ color: 'red' }} />
                    </Popconfirm>,
                  ]}
                  style={{ textAlign: 'center' }}
                >
                  <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    <Avatar size={64} icon={<UserOutlined />} />
                    <Title level={4} style={{ margin: 0 }}>{vendedor.nome}</Title>
                    <Space size="small" align="center">
                      <MailOutlined />
                      <span>{vendedor.email}</span>
                    </Space>
                    <Space size="small" align="center">
                      <PhoneOutlined />
                      <span>{vendedor.contato}</span>
                    </Space>
                    <Space size="small" align="center">
                      <CalendarOutlined />
                      <span>{moment(vendedor.dataNascimento).format('DD/MM/YYYY')}</span>
                    </Space>
                    <span style={{ color: vendedor.status === 'ativo' ? 'green' : 'red' }}>
                      {vendedor.status}
                    </span>
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>

      {/* Modal para Criar/Editar Vendedor */}
      <Modal
        title={formData.id ? 'Editar Vendedor' : 'Novo Vendedor'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          !formData.id && (
            <Button key="random" onClick={gerarVendedorAleatorio}>
              Gerar Aleatório
            </Button>
          ),
          <Button key="submit" type="primary" style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }} onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="vendedorForm"
          initialValues={formData}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Por favor, insira o nome do vendedor!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira o email!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Data de Nascimento"
            name="dataNascimento"
          >
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item
            label="Contato"
            name="contato"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="senha"
            rules={formData.id ? [] : [{ required: true, message: 'Por favor, insira a senha!' }]}
          >
            <Input.Password />
          </Form.Item>
          {formData.id && (
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Por favor, selecione o status!' }]}
            >
              <Select>
                <Option value="ativo">Ativo</Option>
                <Option value="inativo">Inativo</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </Row>
  );
};

export default GerenciarVendedoresConcessionaria;