import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Typography, DatePicker, Row, Col, Card, Spin } from 'antd';
import { UserOutlined, MailOutlined, CalendarOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;

const Perfil = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    dataNascimento: null,
    contato: '',
  });
  const [usuarioId, setUsuarioId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUsuarioId(decodedToken.userId);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  }, []);

  useEffect(() => {
    if (usuarioId) {
      buscarDadosUsuario();
    }
  }, [usuarioId]);

  const buscarDadosUsuario = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/api/vendedores/${usuarioId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFormData({
        ...response.data,
        dataNascimento: response.data.dataNascimento ? moment(response.data.dataNascimento) : null,
      });
    } catch (error) {
      message.error('Erro ao buscar dados do usuário.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values.dataNascimento = values.dataNascimento ? moment(values.dataNascimento).format('YYYY-MM-DD') : null;

      await axios.put(process.env.REACT_APP_API_URL + `/api/vendedores/${usuarioId}`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Perfil atualizado com sucesso!');
    } catch (error) {
      message.error('Erro ao atualizar perfil.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={16} justify="center">
      <Col xs={24} sm={20} md={16} lg={12}>
        <Card style={{ marginTop: '2rem' }}>
          <Spin spinning={loading}>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
              Meu Perfil
            </Title>
            <Form form={form} name="perfilForm" initialValues={formData} onFinish={onFinish} layout="vertical">
              <Form.Item
                label="Nome"
                name="nome"
                rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Por favor, insira seu email!' },
                  { type: 'email', message: 'Por favor, insira um email válido!' },
                ]}
              >
                <Input prefix={<MailOutlined />} disabled />
              </Form.Item>
              <Form.Item label="Data de Nascimento" name="dataNascimento">
                <DatePicker format="DD/MM/YYYY" prefix={<CalendarOutlined />} />
              </Form.Item>
              <Form.Item label="Contato" name="contato">
                <Input prefix={<PhoneOutlined />} />
              </Form.Item>
              <Form.Item
                label="Senha"
                name="senha"
              >
                <Input.Password prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvar Alterações
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

export default Perfil;