import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Typography, Row, Col, Card, Spin, Divider } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import '../components/Shared/Botao.css';
import logo from '../components/img/logo_transparente.png';

const { Text, Title } = Typography;

const LoginPage = ({ setUsuario }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [fadeIn, setFadeIn] = useState(false);

  // Efeito de fade in ao carregar a página
  useEffect(() => {
    setFadeIn(true);
  }, []);

  useEffect(() => {
    const verificado = searchParams.get('verificado');
    if (verificado === 'true') {
      message.success('Conta verificada com sucesso! Faça login para acessar sua conta.');
    }
  }, [searchParams]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/api/auth/login', {
        email: values.email.trim().toLowerCase(),
        senha: values.senha.trim(),
      });

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario', JSON.stringify(response.data.usuario));
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      setUsuario(response.data.usuario); // Define o usuário no estado global
      message.success('Login realizado com sucesso!');

      // Redirecionamento baseado na função do usuário
      switch (response.data.usuario.funcao) {
        case 'Admin':
          navigate('/dashboard/admin');
          break;
        case 'Usuario':
          navigate('/dashboard/usuario');
          break;
        case 'Concessionaria':
          navigate('/dashboard/concessionaria');
          break;
        default:
          navigate('/dashboard');
      }
    } catch (error) {
      console.error("Erro no login:", error);

      if (error.response && error.response.status === 401) {
        if (error.response.data.message.includes('pendente')) {
          message.warning('Sua conta ainda não foi verificada. Verifique seu email.');
          setEmail(values.email); // Armazena o email para reenvio
        } else {
          message.error(error.response.data.message || 'Erro ao fazer login.');
        }
      } else {
        message.error('Erro ao fazer login. Tente novamente mais tarde.');
      }
      setLoading(false);
    } finally{
      setLoading(false)
    }
  };

  const reenviarEmail = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/vendedores/reenviar-email`, { email });
      if (response.status === 200) {
        message.success('Email de verificação reenviado com sucesso!');
        form.setFieldsValue({ email: '' }); // Limpa o campo de email após o reenvio
        setEmail(''); // Limpa o estado de email
      } else {
        message.error(response.data.message || 'Erro ao reenviar email de verificação.');
      }
    } catch (error) {
      console.error('Erro ao reenviar email:', error);
      if (error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Erro ao reenviar email de verificação. Tente novamente mais tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row 
      justify="center" 
      align="middle" 
      style={{ 
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #001529 0%, #003366 100%)',
        padding: '20px'
      }}
    >
      <Col xs={23} sm={20} md={12} lg={8}>
        <div style={{
          opacity: fadeIn ? 1 : 0,
          transform: `translateY(${fadeIn ? 0 : '20px'})`,
          transition: 'all 0.5s ease-in-out',
        }}>
          <Card
            hoverable
            style={{
              borderRadius: '15px',
              boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
              background: 'rgba(255, 255, 255, 0.95)',
            }}
          >
            <div style={{ textAlign: 'center', marginBottom: '30px' }}>
              <img 
                src={logo} 
                alt="Logo" 
                style={{ 
                  width: '150px', 
                  marginBottom: '20px',
                  objectFit: 'contain',
                  height: 'auto'
                }} 
              />
              <Title 
                level={2} 
                style={{ 
                  fontFamily: 'Poppins, sans-serif',
                  color: '#001529',
                  marginBottom: '5px'
                }}
              >
                Bem-vindo de volta!
              </Title>
              <Text type="secondary">
                Acesse sua conta para continuar
              </Text>
            </div>

            <Form
              form={form}
              name="normal_login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              size="large"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Por favor, insira seu email!' },
                  { type: 'email', message: 'Email inválido!' }
                ]}
              >
                <Input 
                  prefix={<MailOutlined style={{ color: '#bfbfbf' }} />}
                  placeholder="Email"
                  autoComplete="email"
                />
              </Form.Item>

              <Form.Item
                name="senha"
                rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
                  placeholder="Senha"
                  autoComplete="current-password"
                />
              </Form.Item>

              {email && (
                <Form.Item>
                  <Button 
                    type="link" 
                    onClick={reenviarEmail} 
                    disabled={loading}
                    icon={loading ? <LoadingOutlined /> : <MailOutlined />}
                    style={{ padding: 0 }}
                  >
                    Reenviar email de verificação
                  </Button>
                </Form.Item>
              )}

              <Form.Item style={{ marginBottom: '12px' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{
                    backgroundColor: '#FF6600',
                    borderColor: '#FF6600',
                    height: '45px',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: '500',
                    boxShadow: '0 2px 8px rgba(255, 102, 0, 0.2)',
                  }}
                  disabled={loading}
                >
                  {loading ? <Spin indicator={<LoadingOutlined style={{ color: 'white' }} />} /> : 'Entrar'}
                </Button>
              </Form.Item>

              <Divider style={{ margin: '24px 0' }}>
                <Text type="secondary">ou</Text>
              </Divider>

              <div style={{ textAlign: 'center' }}>
                <Text type="secondary">
                  Ainda não tem uma conta?{' '}
                  <Link 
                    to="/register"
                    style={{ 
                      color: '#FF6600',
                      fontWeight: '500',
                      transition: 'color 0.3s'
                    }}
                    onMouseEnter={e => e.target.style.color = '#ff8533'}
                    onMouseLeave={e => e.target.style.color = '#FF6600'}
                  >
                    Criar conta
                  </Link>
                </Text>
              </div>
            </Form>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default LoginPage;