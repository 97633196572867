import React, { useState } from 'react';
import { Layout, Menu, Typography, Avatar, Dropdown, Space, Drawer, Button, Badge } from 'antd';
import { UserOutlined, MenuOutlined, LogoutOutlined, DownOutlined, EditOutlined, MessageOutlined } from '@ant-design/icons';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import EditarPerfilModal from '../components/EditarPerfilModal';
import useAuth from '../hooks/useAuth';
import logo from '../components/img/logo_transparente.png';

const { Header, Content } = Layout;
const { Title } = Typography;

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isPerfilModalVisible, setIsPerfilModalVisible] = useState(false);
  const { user, handleLogout } = useAuth();

  const logout = () => {
    handleLogout();
    navigate('/login');
  };

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleAbrirPerfil = () => {
    setIsPerfilModalVisible(true);
  };

  const handleFecharPerfil = () => {
    setIsPerfilModalVisible(false);
  };

  const menuItems = (
    <Menu>
      <Menu.Item key="editar-perfil" icon={<EditOutlined />} onClick={handleAbrirPerfil}>
        Editar Perfil
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        Sair
      </Menu.Item>
    </Menu>
  );

  const renderMenuItems = () => {
    if (!user) {
      return null;
    }

    switch (user.funcao) {
      case 'Admin':
        return (
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/admin" onClick={() => navigate('/dashboard/admin')}>
              Painel
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/gerenciar-administradores" onClick={() => navigate('/dashboard/admin/gerenciar-administradores')}>
              Administradores
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/gerenciar-concessionarias" onClick={() => navigate('/dashboard/admin/gerenciar-concessionarias')}>
             Concessionárias
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/gerenciar-vendedores" onClick={() => navigate('/dashboard/admin/gerenciar-vendedores')}>
              Vendedores
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/gerenciar-anuncios" onClick={() => navigate('/dashboard/admin/gerenciar-anuncios')}>
              Anúncios
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/relatorios" onClick={() => navigate('/dashboard/admin/relatorios')}>
              Relatórios
            </Menu.Item>
            <Menu.Item key="/dashboard/admin/configuracoes" onClick={() => navigate('/dashboard/admin/configuracoes')}>
              Configurações
            </Menu.Item>
          </Menu>
        );
      case 'Concessionaria':
        return (
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
            <Menu.Item key="/dashboard/concessionaria" onClick={() => navigate('/dashboard/concessionaria')}>
              Painel
            </Menu.Item>
            <Menu.Item key="/dashboard/concessionaria/gerenciar-anuncios" onClick={() => navigate('/dashboard/concessionaria/gerenciar-anuncios')}>
              Anúncios
            </Menu.Item>
            <Menu.Item key="/dashboard/concessionaria/gerenciar-vendedores" onClick={() => navigate('/dashboard/concessionaria/gerenciar-vendedores')}>
              Vendedores
            </Menu.Item>
          </Menu>
        );
      case 'Usuario': // Vendedor
        return (
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
         
          
            <Menu.Item key="/dashboard/usuario/publicar-anuncio" onClick={() => navigate('/dashboard/usuario/publicar-anuncio')}>
              Anuncios
            </Menu.Item>
            <Menu.Item key="/dashboard/usuario/solicitar-concessionaria" onClick={() => navigate('/dashboard/usuario/solicitar-concessionaria')}>
              Solicitar Concessionária
            </Menu.Item>
          </Menu>
        );
      default:
        return null;
    }
  };

  return (
    <Layout className="site-layout" >
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
          background: '#001529'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
            style={{ color: 'white', marginRight: '20px' }}
          />
        </div>

        <div style={{ 
          position: 'absolute', 
          left: '50%', 
          transform: 'translateX(-50%)',
          height: '64px',
          display: 'flex',
          alignItems: 'center',
          overflow: 'visible'
        }}>
          <img 
            src={logo} 
            alt="Logo" 
            style={{ 
              height: '50px',
              objectFit: 'contain',
              transform: 'scale(1.2)',
              position: 'relative',
              zIndex: 1001
            }} 
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {user && (
            <Space size={16}>
              <Dropdown overlay={menuItems} placement="bottomRight">
                <Button type="text" shape="round" icon={<UserOutlined />} style={{ color: 'white' }} />
              </Dropdown>
            </Space>
          )}
        </div>
      </Header>
      <Content style={{ margin: '64px 10px 0', overflow: 'initial' }}>
        <div className="site-layout-background" style={{ padding: '10px', textAlign: 'center' }}>
          <Outlet />
        </div>
      </Content>
      <Drawer
        title="Menu"
        placement="left"
        onClose={toggleDrawer}
        visible={drawerVisible}
      >
        <Menu mode="inline" selectedKeys={[location.pathname]} onClick={toggleDrawer}>
          {renderMenuItems()}
          <Menu.Item key="logout" onClick={logout}>
            <LogoutOutlined /> Sair
          </Menu.Item>
        </Menu>
      </Drawer>

      {/* Modal de Edição de Perfil */}
      <EditarPerfilModal
        visible={isPerfilModalVisible}
        onCancel={handleFecharPerfil}
        usuarioId={user?.id}
      />
    </Layout>
  );
};

export default Dashboard;