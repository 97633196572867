// src/components/EditarPerfilModal.js

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Typography, DatePicker, Spin, Modal, Upload, Checkbox } from 'antd';
import { UserOutlined, MailOutlined, CalendarOutlined, PhoneOutlined, LockOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

const { Title } = Typography;

const EditarPerfilModal = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [alterarSenha, setAlterarSenha] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [userType, setUserType] = useState('');
  const [formData, setFormData] = useState({});
  const [initialImageUrl, setInitialImageUrl] = useState('');

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setFormChanged(false);
      buscarDadosUsuario();
    }
  }, [visible]);

  const buscarDadosUsuario = async () => {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      setUserType(decodedToken.funcao);

      let response;
      if (decodedToken.funcao === 'Concessionaria') {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/concessionarias/perfil/dados`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/vendedores/perfil/dados`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
      }

      const dados = response.data;
      setImageUrl(dados.fotoperfil || dados.fotoPerfil || '');
      setInitialImageUrl(dados.fotoperfil || dados.fotoPerfil || '');
      
      const novoFormData = {
        nome: dados.nome || '',
        email: dados.email || '',
        dataNascimento: dados.datanascimento || dados.dataNascimento ? 
          moment(dados.datanascimento || dados.dataNascimento) : null,
        contato: dados.contato || dados.telefone || '',
        // Campos específicos da concessionária
        ...(decodedToken.funcao === 'Concessionaria' && {
          cnpj: dados.cnpj || '',
          razaoSocial: dados.razaoSocial || dados.razaosocial || '',
          logradouro: dados.logradouro || '',
          numero: dados.numero || '',
          complemento: dados.complemento || '',
          bairro: dados.bairro || '',
          cidade: dados.cidade || '',
          estado: dados.estado || '',
        })
      };

      console.log('Dados formatados:', novoFormData); // Para debug
      setFormData(novoFormData);
      form.setFieldsValue(novoFormData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      message.error('Erro ao carregar dados do perfil');
    }
  };

  const handleImageUpload = async (file) => {
    try {
      setUploadLoading(true);
      const formData = new FormData();
      formData.append('foto', file);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload/foto`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      setImageUrl(response.data.url);
      setFormChanged(true);
      return response.data.url;
    } catch (error) {
      message.error('Erro ao fazer upload da imagem');
      console.error('Erro upload:', error);
      return '';
    } finally {
      setUploadLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const dadosAtualizados = {
        ...values,
        dataNascimento: values.dataNascimento ? values.dataNascimento.format('YYYY-MM-DD') : null,
        fotoPerfil: imageUrl,
        senhaAtual: values.senhaAtual
      };

      const endpoint = userType === 'Concessionaria' 
        ? '/api/concessionarias/perfil/atualizar'
        : '/api/vendedores/perfil/atualizar';

      await axios.put(
        `${process.env.REACT_APP_API_URL}${endpoint}`,
        dadosAtualizados,
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          } 
        }
      );

      message.success('Perfil atualizado com sucesso!');
      onCancel();
    } catch (error) {
      console.error('Erro ao atualizar perfil:', error);
      message.error('Erro ao atualizar perfil');
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = () => {
    const currentValues = form.getFieldsValue();
    const hasChanges = Object.keys(currentValues).some(key => {
      if (key === 'senha' || key === 'confirmarSenha') {
        return alterarSenha && currentValues[key];
      }
      return currentValues[key] !== formData[key];
    });
    
    const imageChanged = imageUrl !== initialImageUrl;
    setFormChanged(hasChanges || imageChanged);
  };

  const handleAlterarSenhaChange = (e) => {
    setAlterarSenha(e.target.checked);
    if (!e.target.checked) {
      form.setFieldsValue({
        senha: '',
        confirmarSenha: ''
      });
    }
  };

  // Renderização condicional dos campos do formulário
  const renderFormFields = () => {
    if (userType === 'Concessionaria') {
      return (
        <>
          {/* Campos específicos da concessionária */}
          <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[{ required: true, message: 'Por favor, insira o CNPJ!' }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Razão Social"
            name="razaoSocial"
            rules={[{ required: true, message: 'Por favor, insira a Razão Social!' }]}
          >
            <Input />
          </Form.Item>
          {/* Adicione outros campos específicos da concessionária aqui */}
        </>
      );
    }
    return (
      <>
        {/* Campos do vendedor */}
        <Form.Item
          label="Data de Nascimento"
          name="dataNascimento"
        >
          <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
        </Form.Item>
      </>
    );
  };

  return (
    <Modal
      title="Editar Perfil"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={formData}
          onValuesChange={handleFormChange}
        >
          {/* Upload de Foto */}
          <Form.Item label="Foto de Perfil" style={{ textAlign: 'center' }}>
            <Upload
              name="avatar"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={async (file) => {
                const url = await handleImageUpload(file);
                if (url) {
                  setImageUrl(url);
                }
                return false;
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="Avatar" style={{ width: '100%', borderRadius: '50%' }} />
              ) : (
                <div>
                  {uploadLoading ? <Spin /> : <UploadOutlined />}
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira seu email!' },
              { type: 'email', message: 'Email inválido!' }
            ]}
          >
            <Input prefix={<MailOutlined />} disabled />
          </Form.Item>

          <Form.Item
            label="Telefone"
            name="contato"
            rules={[{ required: true, message: 'Por favor, insira seu telefone!' }]}
          >
            <Input prefix={<PhoneOutlined />} />
          </Form.Item>

          {/* Renderiza campos específicos baseado no tipo de usuário */}
          {renderFormFields()}

          <Form.Item>
            <Checkbox 
              checked={alterarSenha}
              onChange={handleAlterarSenhaChange}
            >
              Alterar senha
            </Checkbox>
          </Form.Item>

          <Form.Item
            label="Nova Senha"
            name="senha"
            rules={[{ min: 6, message: 'A senha deve ter pelo menos 6 caracteres!' }]}
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              disabled={!alterarSenha}
            />
          </Form.Item>

          <Form.Item
            label="Confirmar Nova Senha"
            name="confirmarSenha"
            dependencies={['senha']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('senha') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('As senhas não coincidem!'));
                },
              }),
            ]}
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              disabled={!alterarSenha}
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={loading}
              disabled={!formChanged}
            >
              Salvar Alterações
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditarPerfilModal;