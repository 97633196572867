import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Select,
  Typography,
  InputNumber,
  Row,
  Col,
  Card,
  Divider,
  Alert,
  Upload,
  Avatar,
  Carousel,
  Spin,
  Tag,
  Statistic,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  CarOutlined,
  DollarOutlined,
  UserOutlined,
  PlusOutlined,
  ToolOutlined,
  SearchOutlined,
  ShopOutlined,
  TeamOutlined,
  AppstoreOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Chance from 'chance';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const GerenciarAnunciosConcessionaria = () => {
  const [anuncios, setAnuncios] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [vendedoresPorId, setVendedoresPorId] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    titulo: '',
    descricao: '',
    marca: '',
    modelo: '',
    ano: null,
    preco: null,
    quilometragem: null,
    combustivel: '',
    cor: '',
    vendedorId: null,
    status: 'ativo',
    tipoAnuncio: null, // Define tipoAnuncio como null inicialmente
  });

  // Adicione este novo estado no início do componente
  const [editingAnuncio, setEditingAnuncio] = useState(null);

  const [form] = Form.useForm();
  const [concessionariaId, setConcessionariaId] = useState(null);
  const primeiraExecucao = useRef(true);
  const [fotosUrls, setFotosUrls] = useState([]);
  const [fotosParaUpload, setFotosParaUpload] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
  const [selectedCategory, setSelectedCategory] = useState(null); // Estado para a categoria selecionada

  // Adicione o estado para controlar o tipo de anúncio
  const [tipoAnuncio, setTipoAnuncio] = useState('Veiculo');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.userId) {
          console.log('Token decodificado:', decodedToken);
          setConcessionariaId(decodedToken.userId);
        } else {
          console.error('Token não contém userId');
          message.error('Erro de autenticação. Por favor, faça login novamente.');
        }
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
        message.error('Erro de autenticação. Por favor, faça login novamente.');
      }
    } else {
      console.error('Token não encontrado');
      message.error('Sessão expirada. Por favor, faça login novamente.');
    }
  }, []);

  useEffect(() => {
    if (concessionariaId && primeiraExecucao.current) {
      primeiraExecucao.current = false;
      buscarVendedores().then(() => {
        buscarAnuncios();
      });
    }
  }, [concessionariaId]);

  // Modifique o useEffect que observa isModalVisible para resetar o formulário quando o modal for fechado
  useEffect(() => {
    if (!isModalVisible) {
      form.resetFields();
      setEditingAnuncio(null);
    } else if (editingAnuncio) {
      form.setFieldsValue({
        ...editingAnuncio,
        tipoAnuncio: editingAnuncio.tipoanuncio,
        vendedorId: editingAnuncio.vendedorId,
      });
    }
  }, [isModalVisible, editingAnuncio]);

  const buscarAnuncios = async () => {
    setLoading(true);
    try {
      if (!concessionariaId) {
        console.error('ID da concessionária não encontrado');
        message.error('Erro de autenticação. Por favor, faça login novamente.');
        return;
      }

      console.log('Buscando anúncios para concessionária:', concessionariaId);
      
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/anuncios/concessionaria/${concessionariaId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          timeout: 10000 // 10 segundos de timeout
        }
      );

      const anunciosFormatados = response.data.map((anuncio) => ({
        ...anuncio,
        vendedorNome: anuncio.vendedor_nome,
        concessionariaNome: anuncio.concessionaria_nome,
      }));
      setAnuncios(anunciosFormatados);
    } catch (error) {
      console.error('Erro ao buscar anúncios:', error);
      if (error.response) {
        // Erro com resposta do servidor
        message.error(`Erro ${error.response.status}: ${error.response.data.message || 'Erro ao buscar anúncios'}`);
      } else if (error.request) {
        // Erro sem resposta do servidor
        message.error('Erro de conexão com o servidor. Tente novamente mais tarde.');
      } else {
        message.error('Erro ao buscar anúncios. Por favor, tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  const buscarVendedores = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/api/vendedores/concessionaria/${concessionariaId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setVendedores(response.data);

      // Cria um objeto com os vendedores indexados por ID
      const novosVendedoresPorId = {};
      response.data.forEach((vendedor) => {
        novosVendedoresPorId[vendedor.id] = vendedor.nome;
      });
      setVendedoresPorId(novosVendedoresPorId);
    } catch (error) {
      message.error('Erro ao buscar vendedores.');
      console.error(error);
    }
  };

  // Substitua a função editarAnuncio existente por esta versão atualizada
  const editarAnuncio = async (anuncio) => {
    try {
      setEditingAnuncio(anuncio);
      
      // Formatar dados do anúncio para o formulário
      const dadosFormulario = {
        ...anuncio,
        tipoAnuncio: anuncio.tipoanuncio, // Manter o valor original
        vendedorId: anuncio.vendedorid,
        vendedorNome: anuncio.vendedor_nome,
        fotos: anuncio.fotos || []
      };

      setFormData(dadosFormulario);
      setFotosUrls(anuncio.fotos || []);
      setIsModalVisible(true);

      // Atualizar formulário após o modal estar visível
      setTimeout(() => {
        form.setFieldsValue(dadosFormulario);
      }, 100);

    } catch (error) {
      console.error('Erro ao editar anúncio:', error);
      message.error('Erro ao carregar dados do anúncio.');
    }
  };

  const excluirAnuncio = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/anuncios/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Anúncio excluído com sucesso!');
      buscarAnuncios();
    } catch (error) {
      message.error('Erro ao excluir anúncio.');
      console.error(error);
    }
  };

  // Função para gerar dados aleatórios do anúncio
  const gerarAnuncioAleatorio = () => {
    const chance = new Chance();

    // Dados aleatórios para o formulário
    const dadosAleatorios = {
      titulo: chance.sentence({ words: 5 }),
      descricao: chance.paragraph({ sentences: 2 }),
      marca: chance.pickone(['Chevrolet', 'Ford', 'Fiat', 'Volkswagen', 'Toyota', 'Honda', 'Hyundai', 'Renault']),
      modelo: chance.word(),
      ano: chance.year({ min: 2010, max: 2023 }),
      preco: chance.floating({ min: 10000, max: 100000, fixed: 2 }),
      quilometragem: chance.integer({ min: 0, max: 200000 }),
      combustivel: chance.pickone(['Gasolina', 'Álcool', 'Flex', 'Diesel']),
      cor: chance.color({ format: 'name' }),
      // Verifica se há vendedores cadastrados
      vendedorId: vendedores.length > 0 ? chance.pickone(vendedores).id : null,
      status: chance.pickone(['ativo', 'inativo', 'vendido']),
      tipoAnuncio: formData.tipoAnuncio, // Define o tipo de anúncio como "Veiculo"
    };

    // Preenche os campos do formulário com os dados aleatórios
    form.setFieldsValue(dadosAleatorios);
  };

  const handleRemovePhoto = (file) => {
    const index = fotosUrls.indexOf(file.url);
    if (index > -1) {
      setFotosUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
      setFotosParaUpload((prevFotos) => prevFotos.filter((f) => f.uid !== file.uid)); // Remove o arquivo da lista local
    }
  };

  // Função para lidar com a mudança no termo de pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Função para lidar com a mudança na categoria selecionada
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  // Função para filtrar os anúncios
  const filteredAnuncios = anuncios.filter((anuncio) => {
    // Filtra por termo de pesquisa
    const matchesSearchTerm = anuncio.titulo.toLowerCase().includes(searchTerm.toLowerCase());

    // Filtra por categoria
    const matchesCategory = selectedCategory === null || anuncio.tipoanuncio === selectedCategory;

    return matchesSearchTerm && matchesCategory;
  });

  // Mover as funções showModal, handleCancel e handleUpload para o escopo do componente
  const showModal = () => {
    if (vendedores.length === 0) {
      message.info({
        content: (
          <span>
            Você precisa cadastrar um vendedor antes de criar um anúncio. Acesse a página{' '}
            <a href="/dashboard/concessionaria/gerenciar-vendedores">Gerenciar Vendedores</a>{' '}
          </span>
        ),
        duration: 5,
      });
      return;
    }
    // Abre o modal sem definir o tipo de anúncio
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({
      titulo: '',
      descricao: '',
      marca: '',
      modelo: '',
      ano: null,
      preco: null,
      quilometragem: null,
      combustivel: '',
      cor: '',
      vendedorId: null,
      status: 'ativo',
      tipoAnuncio: null, // Reseta o tipo de anúncio ao fechar o modal
    });
    setFotosUrls([]);
    setFotosParaUpload([]);
    setUploadProgress({});
    setEditingAnuncio(null);
  };

  const handleUpload = async (values) => {
    try {
      setLoading(true);
      let fotosFinais = [...fotosUrls];

      // Upload das novas fotos em lote
      if (fotosParaUpload.length > 0) {
        const uploadPromises = fotosParaUpload.map(async (foto) => {
          const formData = new FormData();
          formData.append('foto', foto.originFileObj);

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/api/upload/foto`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress((prevProgress) => ({
                    ...prevProgress,
                    [foto.uid]: percentCompleted,
                  }));
                },
              }
            );
            return response.data.url;
          } catch (error) {
            console.error(`Erro ao fazer upload da foto ${foto.name}:`, error);
            message.error(`Erro ao fazer upload da foto ${foto.name}`);
            return null;
          }
        });

        const novasFotos = await Promise.all(uploadPromises);
        fotosFinais = [...fotosFinais, ...novasFotos.filter(url => url !== null)];
      }

      // Preparar dados do anúncio baseado no tipo
      const anuncioData = {
        ...values,
        preco: parseFloat(values.preco) || 0,
        fotos: fotosFinais,
        tipoanuncio: values.tipoAnuncio,
        vendedorId: values.vendedorId,
        status: values.status || 'ativo',
        concessionariaId
      };

      // Adicionar campos específicos baseado no tipo de anúncio
      if (values.tipoAnuncio === 'Veiculo') {
        anuncioData.ano = parseInt(values.ano, 10) || 0;
        anuncioData.quilometragem = parseInt(values.quilometragem, 10) || 0;
        anuncioData.combustivel = values.combustivel;
        anuncioData.cor = values.cor;
      } else {
        anuncioData.categoria = values.categoria;
        anuncioData.estado = values.estado;
      }

      if (editingAnuncio) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/anuncios/${editingAnuncio.id}`,
          anuncioData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        message.success('Anúncio atualizado com sucesso!');
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/anuncios`,
          anuncioData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        message.success('Anúncio criado com sucesso!');
      }

      handleCancel();
      buscarAnuncios();
    } catch (error) {
      console.error('Erro ao salvar anúncio:', error);
      message.error('Erro ao salvar anúncio. Verifique os dados e tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={[16, 16]}>
      {/* Coluna Gerenciar Anúncios e Estatísticas */}
      <Col xs={24} lg={10}>
        <Card>
          <Title level={2}>Gerenciar Anúncios</Title>
          <Button 
            type="primary" 
            onClick={showModal} 
            style={{ 
              marginBottom: '1rem',
              width: '100%',
              backgroundColor: '#FF6600',
              borderColor: '#FF6600'
            }} 
            disabled={vendedores.length === 0}
          >
            Novo Anúncio
          </Button>

          {vendedores.length === 0 && (
            <Alert
              message={
                <span>
                  Você ainda não possui vendedores cadastrados. Acesse a página{' '}
                  <a href="/dashboard/concessionaria/gerenciar-vendedores">Gerenciar Vendedores</a>{' '} 
                </span>
              }
              type="info"
              showIcon
              style={{ marginBottom: '1rem' }}
            />
          )}

          {/* Estatísticas dos Anúncios */}
          <Divider orientation="left">Estatísticas dos Anúncios</Divider>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Total de Anúncios"
                  value={anuncios.length}
                  prefix={<AppstoreOutlined style={{ color: '#FF6600' }} />}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Anúncios Ativos"
                  value={anuncios.filter(a => a.status === 'ativo').length}
                  prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Veículos"
                  value={anuncios.filter(a => a.tipoanuncio === 'Veiculo').length}
                  prefix={<CarOutlined style={{ color: '#1890ff' }} />}
                  valueStyle={{ color: '#1890ff' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Peças"
                  value={anuncios.filter(a => a.tipoanuncio === 'Peca').length}
                  prefix={<ToolOutlined style={{ color: '#722ed1' }} />}
                  valueStyle={{ color: '#722ed1' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Vendidos"
                  value={anuncios.filter(a => a.status === 'vendido').length}
                  prefix={<DollarOutlined style={{ color: '#FF6600' }} />}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12}>
              <Card style={{ textAlign: 'center', background: '#f0f2f5' }}>
                <Statistic
                  title="Vendedores"
                  value={vendedores.length}
                  prefix={<TeamOutlined style={{ color: '#FF6600' }} />}
                  valueStyle={{ color: '#FF6600' }}
                />
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>

      {/* Coluna Lista de Anúncios */}
      <Col xs={24} lg={14}>
        <Card>
          <Title level={2}>Lista de Anúncios</Title>
          
          {/* Filtros e Pesquisa */}
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col xs={24} md={12}>
              <Select
                placeholder="Filtrar por categoria"
                onChange={handleCategoryChange}
                value={selectedCategory}
                size="large"
                style={{ width: '100%' }}
                allowClear
              >
                <Option value="Veiculo">Veículo</Option>
                <Option value="Peca">Peça</Option>
              </Select>
            </Col>
            <Col xs={24} md={12}>
              <Input.Search
                placeholder="Pesquisar anúncios..."
                value={searchTerm}
                onChange={handleSearchChange}
                enterButton="Buscar"
                size="large"
                prefix={<SearchOutlined />}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

          {/* Lista de Anúncios */}
          <Row gutter={[16, 16]}>
            {filteredAnuncios.map((anuncio) => (
              <Col xs={24} sm={24} md={24} lg={12} key={anuncio.id} style={{ display: 'flex' }}>
                <Card
                  style={{ 
                    width: '100%', 
                    marginBottom: '1rem',
                    height: '100%'
                  }}
                  cover={
                    anuncio.fotos && anuncio.fotos.length > 0 && (
                      <img 
                        alt={anuncio.titulo} 
                        src={anuncio.fotos[0]} 
                        style={{ 
                          height: '200px', 
                          objectFit: 'cover' 
                        }} 
                      />
                    )
                  }
                  actions={[
                    <EditOutlined 
                      key="edit" 
                      onClick={() => editarAnuncio(anuncio)}
                      style={{ color: '#FF6600' }}
                    />,
                    <Popconfirm
                      title="Tem certeza que deseja excluir este anúncio?"
                      onConfirm={() => excluirAnuncio(anuncio.id)}
                      okText="Sim"
                      cancelText="Não"
                      okButtonProps={{ style: { backgroundColor: '#FF6600', borderColor: '#FF6600' } }}
                    >
                      <DeleteOutlined key="delete" style={{ color: '#FF6600' }} />
                    </Popconfirm>,
                  ]}
                >
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Title level={4} style={{ 
                      margin: 0, 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap' 
                    }}>
                      {anuncio.titulo}
                    </Title>
                    <Space size="small" align="center">
                      <Tag color="purple">{anuncio.codigo}</Tag>
                      <Tag color={anuncio.tipoanuncio === 'Peca' ? 'blue' : 'green'}>
                        {anuncio.tipoanuncio === 'Veiculo' ? 'Veículo' : 'Peça'}
                      </Tag>
                      <Tag color={
                        anuncio.status === 'ativo' ? 'green' : 
                        anuncio.status === 'vendido' ? 'blue' : 'red'
                      }>
                        {anuncio.status.charAt(0).toUpperCase() + anuncio.status.slice(1)}
                      </Tag>
                    </Space>
                    <Paragraph
                      ellipsis={{ rows: 3, expandable: true, symbol: 'mais' }}
                      style={{ maxHeight: '4.5em', overflow: 'hidden' }}
                    >
                      {anuncio.descricao}
                    </Paragraph>
                    <Space>
                      <DollarOutlined />
                      <span>R$ {parseFloat(anuncio.preco).toFixed(2)}</span>
                    </Space>
                    <Space>
                      <UserOutlined />
                      <span>{anuncio.vendedorNome || 'Vendedor não atribuído'}</span>
                    </Space>
                    {anuncio.marca && anuncio.modelo && (
                      <Space>
                        <CarOutlined />
                        <span>{`${anuncio.marca} ${anuncio.modelo} (${anuncio.ano})`}</span>
                      </Space>
                    )}
                  </Space>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </Col>

      {/* Modal de Novo/Editar Anúncio */}
      <Modal
        title={editingAnuncio ? "Editar Anúncio" : "Novo Anúncio"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button 
            key="random" 
            onClick={gerarAnuncioAleatorio}
            style={{ backgroundColor: '#722ed1', borderColor: '#722ed1', color: 'white' }}
          >
            Gerar Dados Aleatórios
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
            style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }}
          >
            {editingAnuncio ? "Atualizar" : "Publicar"}
          </Button>,
        ]}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpload}
          initialValues={editingAnuncio || {}}
        >
          {/* Seleção de Tipo de Anúncio */}
          <Form.Item
            name="tipoAnuncio"
            label="Tipo de Anúncio"
            rules={[{ required: true, message: 'Selecione o tipo de anúncio' }]}
          >
            <Select onChange={(value) => setTipoAnuncio(value)}>
              <Option value="Veiculo">Veículo</Option>
              <Option value="Peca">Peça</Option>
            </Select>
          </Form.Item>

          {/* Seleção de Vendedor */}
          <Form.Item
            name="vendedorId"
            label="Vendedor"
            rules={[{ required: true, message: 'Selecione o vendedor' }]}
          >
            <Select>
              {vendedores.map(vendedor => (
                <Option key={vendedor.id} value={vendedor.id}>
                  {vendedor.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Campos comuns */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="titulo"
                label="Título"
                rules={[{ required: true, message: 'Digite o título do anúncio' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="descricao"
                label="Descrição"
                rules={[{ required: true, message: 'Digite a descrição do anúncio' }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          {/* Campos específicos baseados no tipo de anúncio */}
          {tipoAnuncio === 'Veiculo' ? (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="marca"
                    label="Marca"
                    rules={[{ required: true, message: 'Digite a marca' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="modelo"
                    label="Modelo"
                    rules={[{ required: true, message: 'Digite o modelo' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="ano"
                    label="Ano"
                    rules={[{ required: true, message: 'Digite o ano' }]}
                  >
                    <InputNumber style={{ width: '100%' }} min={1900} max={2024} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="quilometragem"
                    label="Quilometragem"
                    rules={[{ required: true, message: 'Digite a quilometragem' }]}
                  >
                    <InputNumber style={{ width: '100%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="combustivel"
                    label="Combustível"
                    rules={[{ required: true, message: 'Selecione o combustível' }]}
                  >
                    <Select>
                      <Option value="Gasolina">Gasolina</Option>
                      <Option value="Álcool">Álcool</Option>
                      <Option value="Flex">Flex</Option>
                      <Option value="Diesel">Diesel</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="categoria"
                  label="Categoria da Peça"
                  rules={[{ required: true, message: 'Selecione a categoria' }]}
                >
                  <Select>
                    <Option value="Motor">Motor</Option>
                    <Option value="Suspensão">Suspensão</Option>
                    <Option value="Freios">Freios</Option>
                    <Option value="Elétrica">Elétrica</Option>
                    <Option value="Carroceria">Carroceria</Option>
                    <Option value="Outros">Outros</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="estado"
                  label="Estado"
                  rules={[{ required: true, message: 'Selecione o estado' }]}
                >
                  <Select>
                    <Option value="Novo">Novo</Option>
                    <Option value="Usado">Usado</Option>
                    <Option value="Recondicionado">Recondicionado</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="preco"
                label="Preço"
                rules={[{ required: true, message: 'Digite o preço' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={0}
                  formatter={value => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={value => value.replace(/R\$\s?|(\.*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: 'Selecione o status' }]}
              >
                <Select>
                  <Option value="ativo">Ativo</Option>
                  <Option value="inativo">Inativo</Option>
                  <Option value="vendido">Vendido</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Upload de Fotos */}
          <Form.Item label="Fotos">
            <Upload
              listType="picture-card"
              multiple={true} // Permite selecionar múltiplas imagens
              fileList={[
                ...fotosUrls.map((url, index) => ({
                  uid: `existing-${index}`,
                  name: `foto-${index}`,
                  status: 'done',
                  url: url,
                })),
                ...fotosParaUpload
              ]}
              onRemove={(file) => {
                if (file.url) {
                  setFotosUrls(fotosUrls.filter(url => url !== file.url));
                } else {
                  setFotosParaUpload(fotosParaUpload.filter(f => f.uid !== file.uid));
                }
                return true;
              }}
              onChange={({ fileList }) => {
                const validFiles = fileList.filter(file => 
                  (!file.url && file.status !== 'error' && file.originFileObj) || file.url
                );
                const newFiles = validFiles.filter(file => !file.url);
                setFotosParaUpload(newFiles);
              }}
              beforeUpload={(file) => {
                const isImage = file.type.startsWith('image/');
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isImage) {
                  message.error('Você só pode fazer upload de imagens!');
                  return Upload.LIST_IGNORE;
                }
                if (!isLt2M) {
                  message.error('A imagem deve ser menor que 2MB!');
                  return Upload.LIST_IGNORE;
                }

                return false;
              }}
            >
              {fotosUrls.length + fotosParaUpload.length >= 8 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload (Máx: 8)</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      {/* Estilo global para os botões */}
      <style jsx global>{`
        .ant-btn-primary {
          background-color: #FF6600 !important;
          border-color: #FF6600 !important;
        }
        .ant-btn-primary:hover {
          background-color: #e65c00 !important;
          border-color: #e65c00 !important;
        }
      `}</style>
    </Row>
  );
};

export default GerenciarAnunciosConcessionaria;