import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from './Footer';
import SearchBar from './SearchBar';
import VehicleCard from './VehicleCard';
import './Vitrine.css';
import ImageCarouselModal from './ImageCarouselModal';
import { Spin, message } from 'antd';
import GoogleAd from './GoogleAd';

// Constantes para os slots de anúncios
const AD_SLOTS = {
  HORIZONTAL: '1234567890', // Substitua pelos seus slots reais
  SQUARE: '0987654321',
  RESPONSIVE: '1357924680'
};

function Vitrine() {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [category, setCategory] = useState('todos');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const buscarAnuncios = async () => {
    setIsLoading(true);
    try {
      const apiUrl = new URL('/api/vitrine', process.env.REACT_APP_API_URL);
      if (category !== 'todos') {
        apiUrl.searchParams.append('categoria', category);
      }
      const response = await axios.get(apiUrl.toString());

      console.log('Resposta da API:', response.data);

      const anunciosFormatados = response.data.map(anuncio => {
        const vendedorContato = anuncio.vendedor?.contato ||
                               anuncio.vendedor_contato ||
                               null;

        console.log('Contato do vendedor recebido:', vendedorContato);

        return {
          ...anuncio,
          id: anuncio.id,
          name: anuncio.titulo,
          price: parseFloat(anuncio.preco),
          year: parseInt(anuncio.ano) || 0,
          manufacturer: anuncio.marca || 'Não informado',
          model: anuncio.modelo || 'Não informado',
          codigo: anuncio.codigo,
          description: anuncio.descricao || 'Sem descrição',
          images: Array.isArray(anuncio.fotos) ? anuncio.fotos : [],
          tipoanuncio: anuncio.tipoanuncio,
          vendedor: anuncio.vendedor_nome || 'Vendedor não informado',
          vendedor_foto: anuncio.vendedor_foto || null,
          concessionaria: anuncio.concessionaria_nome || 'Concessionária não informada',
          concessionaria_foto: anuncio.concessionaria_foto || null,
          vendedor_contato: vendedorContato ? vendedorContato.replace(/\D/g, '') : null
        };
      });

      console.log('Primeiro anúncio formatado:', anunciosFormatados[0]);

      setVehicles(anunciosFormatados);
      setFilteredVehicles(anunciosFormatados);
    } catch (error) {
      console.error('Erro ao buscar anúncios para a vitrine:', error);
      message.error('Erro ao carregar anúncios. Por favor, tente novamente mais tarde.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    buscarAnuncios();
  }, []);

  useEffect(() => {
    if (category === 'todos') {
      setFilteredVehicles(vehicles);
    } else {
      setFilteredVehicles(
        vehicles.filter(anuncio => anuncio.tipoanuncio.toLowerCase() === category)
      );
    }
  }, [category, vehicles]);

  const handleSearch = (searchTerm) => {
    const filtered = vehicles.filter(anuncio =>
      (anuncio.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       anuncio.codigo.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (category === 'todos' || anuncio.tipoanuncio.toLowerCase() === category)
    );
    setFilteredVehicles(filtered);
  };

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const handleOpenModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsModalOpen(true);
  };

  return (
    <div className="vitrine-page">
    
      
      {/* Container flexível para anúncios */}
      <div className="ads-section">
        {/* Anúncio principal responsivo */}
        <div className="ad-container main-ad">
          <GoogleAd 
            slot={AD_SLOTS.RESPONSIVE}
            format="auto"
            responsive={true}
          />
        </div>

        {/* Opcionalmente, você pode adicionar mais formatos de anúncios */}
        <div className="secondary-ads">
          <div className="ad-container">
            <GoogleAd 
              slot={AD_SLOTS.SQUARE}
              format="square"
              responsive={false}
            />
          </div>
        </div>
      </div>

      <div className="vitrine-content">
        <div className="search-bar-container">
          <SearchBar onSearch={handleSearch} />
        </div>
        <div className="category-buttons">
          <button onClick={() => handleCategoryChange('todos')}>Todos</button>
          <button onClick={() => handleCategoryChange('veiculo')}>Veículos</button>
          <button onClick={() => handleCategoryChange('peca')}>Peças</button>
        </div>
        <div className="vehicle-grid-container">
          {isLoading ? (
            <Spin size="large" />
          ) : filteredVehicles.length > 0 ? (
            <div className="vehicle-grid">
              {filteredVehicles.map(vehicle => (
                <VehicleCard
                  key={vehicle.id}
                  vehicle={vehicle}
                  onOpenModal={handleOpenModal}
                  vendedorContato={vehicle.vendedor_contato}
                />
              ))}
            </div>
          ) : (
            <div className="no-results">Nenhum item encontrado.</div>
          )}
        </div>
      </div>

      {isModalOpen && selectedVehicle && (
        <ImageCarouselModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          vehicle={selectedVehicle}
          vendedorContato={selectedVehicle.vendedor_contato}
        />
      )}
    </div>
  );
}

export default Vitrine;
