import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Space,
  Popconfirm,
  Typography,
  Row,
  Col,
  Card,
} from 'antd';
import axios from 'axios';

const { Title } = Typography;

const GerenciarAdministradores = () => {
  const [administradores, setAdministradores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({ id: null, nome: '', email: '', senha: '' });
  const [form] = Form.useForm();

  useEffect(() => {
    buscarAdministradores();
  }, []);

  const buscarAdministradores = async () => {
    setLoading(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/usuarios', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setAdministradores(response.data);
    } catch (error) {
      message.error('Erro ao buscar administradores.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFormData({ id: null, nome: '', email: '', senha: '' });
  };

  const onFinish = async (values) => {
    try {
      if (formData.id) {
        // Editar administrador
        await axios.put(process.env.REACT_APP_API_URL + `/api/usuarios/${formData.id}`, values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Administrador atualizado com sucesso!');
      } else {
        // Criar administrador
        await axios.post(process.env.REACT_APP_API_URL + '/api/usuarios', values, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        message.success('Administrador criado com sucesso!');
      }
      handleCancel();
      buscarAdministradores();
    } catch (error) {
      message.error('Erro ao salvar administrador.');
      console.error(error);
    }
  };

  const editarAdministrador = (administrador) => {
    setFormData(administrador);
    showModal();
  };

  const excluirAdministrador = async (id) => {
    try {
      await axios.delete(process.env.REACT_APP_API_URL + `/api/usuarios/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      message.success('Administrador excluído com sucesso!');
      buscarAdministradores();
    } catch (error) {
      message.error('Erro ao excluir administrador.');
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Ações',
      key: 'acoes',
      render: (text, administrador) => (
        <Space size="middle">
          <Button type="link" onClick={() => editarAdministrador(administrador)}>
            Editar
          </Button>
          <Popconfirm
            title="Tem certeza que deseja excluir este administrador?"
            onConfirm={() => excluirAdministrador(administrador.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button type="link" danger>
              Excluir
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Title level={2}>Gerenciar Administradores</Title>
          <Button type="primary" onClick={showModal} style={{ marginBottom: '1rem', backgroundColor: '#FF6600', borderColor: '#FF6600' }}>
            Novo Administrador
          </Button>
          <Table
            dataSource={administradores}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }} // Adiciona rolagem horizontal
          />
        </Card>
      </Col>
      <Modal
        title={formData.id ? 'Editar Administrador' : 'Novo Administrador'}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" style={{ backgroundColor: '#FF6600', borderColor: '#FF6600' }} onClick={() => form.submit()}>
            Salvar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="administradorForm"
          initialValues={formData}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: 'Por favor, insira o nome do administrador!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira o email!' },
              { type: 'email', message: 'Por favor, insira um email válido!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Senha"
            name="senha"
            rules={formData.id ? [] : [{ required: true, message: 'Por favor, insira a senha!' }]} // Senha obrigatória apenas no cadastro
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default GerenciarAdministradores;