import React, { useState, useEffect } from 'react';
import {
  Select,
  DatePicker,
  Button,
  Typography,
  Table,
  Row,
  Col,
  Card,
  Spin,
  message,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const GerenciarRelatorios = () => {
  const [tipoRelatorio, setTipoRelatorio] = useState('anunciosPorConcessionaria');
  const [periodo, setPeriodo] = useState([moment().startOf('month'), moment()]);
  const [concessionariaId, setConcessionariaId] = useState(null);
  const [vendedorId, setVendedorId] = useState(null);
  const [dadosRelatorio, setDadosRelatorio] = useState([]);
  const [concessionarias, setConcessionarias] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [loading, setLoading] = useState(false);


  const renderRelatorio = () => {
    switch (tipoRelatorio) {
      case 'anunciosPorConcessionaria':
        return (
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <Card title="Anúncios por Concessionária">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={dadosRelatorio}>
                    <XAxis dataKey="nome" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quantidadeAnuncios" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title="Tabela de Dados">
                <Table
                  dataSource={dadosRelatorio}
                  columns={[
                    { title: 'Concessionária', dataIndex: 'nome', key: 'nome' },
                    {
                      title: 'Quantidade de Anúncios',
                      dataIndex: 'quantidadeAnuncios',
                      key: 'quantidadeAnuncios',
                    },
                  ]}
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        );
      case 'anunciosPorVendedor':
        return (
          <Card title="Anúncios por Vendedor">
            <Row gutter={16} style={{ marginBottom: '1rem' }}>
              <Col xs={24} sm={12} md={8}>
                <Select
                  placeholder="Selecione a concessionária"
                  onChange={(value) => setConcessionariaId(value)}
                  value={concessionariaId}
                >
                  {concessionarias.map((concessionaria) => (
                    <Option key={concessionaria.id} value={concessionaria.id}>
                      {concessionaria.nome}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {concessionariaId && (
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12}>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={dadosRelatorio}>
                      <XAxis dataKey="nome" />
                      <YAxis />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="quantidadeAnuncios" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Card title="Tabela de Dados">
                    <Table
                      dataSource={dadosRelatorio}
                      columns={[
                        { title: 'Vendedor', dataIndex: 'nome', key: 'nome' },
                        {
                          title: 'Quantidade de Anúncios',
                          dataIndex: 'quantidadeAnuncios',
                          key: 'quantidadeAnuncios',
                        },
                      ]}
                      pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          </Card>
        );
      case 'anunciosPorStatus':
        return (
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12}>
              <Card title="Anúncios por Status">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={dadosRelatorio}>
                    <XAxis dataKey="status" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quantidade" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Card title="Tabela de Dados">
                <Table
                  dataSource={dadosRelatorio}
                  columns={[
                    { title: 'Status', dataIndex: 'status', key: 'status' },
                    { title: 'Quantidade', dataIndex: 'quantidade', key: 'quantidade' },
                  ]}
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        );
      case 'vendasPorPeriodo':
        return (
          <Card title="Vendas por Período">
            <Row gutter={16} style={{ marginBottom: '1rem' }}>
              <Col xs={24} sm={12} md={8}>
                <RangePicker
                  value={periodo}
                  onChange={(dates) => setPeriodo(dates)}
                  format="DD/MM/YYYY"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={dadosRelatorio}>
                    <XAxis dataKey="data" tickFormatter={(tick) => moment(tick).format('DD/MM')} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="quantidadeVendas" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Card title="Tabela de Dados">
                  <Table
                    dataSource={dadosRelatorio}
                    columns={[
                      {
                        title: 'Data',
                        dataIndex: 'data',
                        key: 'data',
                        render: (data) => moment(data).format('DD/MM/YYYY'),
                      },
                      {
                        title: 'Quantidade de Vendas',
                        dataIndex: 'quantidadeVendas',
                        key: 'quantidadeVendas',
                      },
                    ]}
                    pagination={false}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        );
      case 'usuariosAtivos':
        return (
          <Card title="Usuários Ativos">
            <Table
              dataSource={dadosRelatorio}
              columns={[
                { title: 'Tipo de Usuário', dataIndex: 'tipoUsuario', key: 'tipoUsuario' },
                { title: 'Quantidade', dataIndex: 'quantidade', key: 'quantidade' },
              ]}
              pagination={false}
            />
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Title level={2}>Relatórios</Title>
      <Row gutter={16} style={{ marginBottom: '1rem' }}>
        <Col xs={24} sm={12} md={8}>
          <Select
            placeholder="Selecione o tipo de relatório"
            onChange={(value) => setTipoRelatorio(value)}
            value={tipoRelatorio}
          >
            <Option value="anunciosPorConcessionaria">Anúncios por Concessionária</Option>
            <Option value="anunciosPorVendedor">Anúncios por Vendedor</Option>
            <Option value="anunciosPorStatus">Anúncios por Status</Option>
            <Option value="vendasPorPeriodo">Vendas por Período</Option>
            <Option value="usuariosAtivos">Usuários Ativos</Option>
          </Select>
        </Col>
        {/* Adicione mais filtros aqui, se necessário */}
      </Row>
      {loading ? (
        <Spin size="large" />
      ) : (
        renderRelatorio()
      )}
    </div>
  );
};

export default GerenciarRelatorios;