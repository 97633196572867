import React, { useState } from 'react';
import { 
  CalendarOutlined, 
  CarOutlined, 
  DollarOutlined, 
  TagOutlined,
  UserOutlined, 
  ShopOutlined, 
  WhatsAppOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined
} from '@ant-design/icons';
import './ImageCarouselModal.css';
import { Modal, Row, Col, Carousel, Divider, Descriptions, Typography, Avatar, Button, Tag, message } from 'antd';

const { Title, Text, Paragraph } = Typography;

function ImageCarouselModal({ onClose, vehicle }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = vehicle.images && vehicle.images.length > 0 ? vehicle.images : [vehicle.image];

  const formatarPreco = (preco) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(preco);
  };

  const handleWhatsAppClick = (e) => {
    e?.stopPropagation();
    
    if (!vehicle.vendedor_contato) {
      message.error('Número de contato do vendedor não disponível');
      return;
    }

    const phoneNumber = vehicle.vendedor_contato.replace(/\D/g, '');
    
    const formattedPhone = phoneNumber.startsWith('55') ? phoneNumber : `55${phoneNumber}`;
    
    const messageText = `Olá! Vi seu anúncio do ${vehicle.name} (Código: ${vehicle.codigo}) no site e gostaria de mais informações.`;
    
    const whatsappUrl = `https://wa.me/${formattedPhone}?text=${encodeURIComponent(messageText)}`;
    
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content vehicle-detail-modal">
        <button className="close-button" onClick={onClose}>×</button>
        
        <Row gutter={[24, 24]}>
          {/* Coluna da Esquerda - Carrossel de Imagens */}
          <Col xs={24} md={14}>
            <div className="carousel-container">
              <Carousel autoplay>
                {images.map((image, index) => (
                  <div key={index} className="carousel-item">
                    <img src={image} alt={`${vehicle.name} - Imagem ${index + 1}`} />
                  </div>
                ))}
              </Carousel>
            </div>

            {/* Detalhes do Veículo em Tabela */}
            <div className="details-section">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Ano">
                  <CalendarOutlined /> {vehicle.year}
                </Descriptions.Item>
                <Descriptions.Item label="Marca">
                  <TagOutlined /> {vehicle.manufacturer}
                </Descriptions.Item>
                <Descriptions.Item label="Modelo">
                  <CarOutlined /> {vehicle.model}
                </Descriptions.Item>
                <Descriptions.Item label="Publicado">
                  <FieldTimeOutlined /> {vehicle.createdat ? new Date(vehicle.createdat).toLocaleDateString() : 'Data não disponível'}
                </Descriptions.Item>
              </Descriptions>
            </div>

            {/* Descrição Centralizada */}
            <div className="description-section">
              <Title level={4}>Descrição</Title>
              <Paragraph>{vehicle.description}</Paragraph>
            </div>
          </Col>

          {/* Coluna da Direita - Informações */}
          <Col xs={24} md={10} className="vehicle-info">
            {/* Cabeçalho */}
            <div className="header-section">
              <Tag color={vehicle.tipoanuncio === 'Peca' ? 'blue' : 'green'}>
                {vehicle.tipoanuncio === 'Peca' ? 'Peça' : 'Veículo'}
              </Tag>
              <Title level={2}>{vehicle.name}</Title>
              <Text type="secondary">Código: {vehicle.codigo}</Text>
              <Title level={3} className="price">
                {formatarPreco(vehicle.price)}
              </Title>
            </div>

            <Divider />

            {/* Informações de Contato */}
            <div className="contact-section">
              <div className="vendor-info">
                <Avatar size={64} src={vehicle.vendedor_foto} icon={<UserOutlined />} />
                <div className="vendor-details">
                  <Text strong>Vendedor</Text>
                  <Text>{vehicle.vendedor}</Text>
                </div>
              </div>

              {vehicle.concessionaria && (
                <div className="dealer-info">
                  <Avatar size={64} src={vehicle.concessionaria_foto} icon={<ShopOutlined />} />
                  <div className="dealer-details">
                    <Text strong>Concessionária</Text>
                    <Text>{vehicle.concessionaria}</Text>
                  </div>
                </div>
              )}

              <Button 
                type="primary" 
                icon={<WhatsAppOutlined />} 
                block 
                onClick={handleWhatsAppClick}
                className="whatsapp-button"
              >
                Contatar Vendedor
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ImageCarouselModal;