import React from 'react';
import './Footer.css';
import logoAds from './img/logo_transparente.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logoAds} alt="Ads Auto Logo" />
        </div>
        <div className="footer-info">
          <h3>Ads Auto</h3>
          <p>14 99740-6893</p>
          <p><a href="https://www.adsauto.com.br" target="_blank" rel="noopener noreferrer">https://www.adsauto.com.br</a></p>
          <p>Lençóis Paulista - SP</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>2024 © ADS Auto. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;